import React from 'react' 
import Text from '../text/Text' 
import {setShowMenu,setShowModal,setModalContent,setContracts,setCompletedContracts} from '../../actions'; 
import { connect } from 'react-redux';  
import Theme from '../../components/theme/Theme'
import {   milliToFormattedDate } from '../../utilities' 

const ContractCard = (props) => {
  const theme = Theme() 
  return (
    <div key={props?.item?.id} onClick={(e)=>{}} style={{display:'flex',margin:'auto',width:'90%',marginLeft:'auto',marginRight:'auto',marginTop:'5px',padding:'5px',paddingTop:'2px', backgroundColor:props?.index%2 === 0? 'rgba(0,0,0,0.01)' :'rgba(0,0,0,0.03)',borderRadius:'8px'}}>
      <Text style={{marginTop:'auto',fontSize:theme?.isPastBreak?'12px':'15px',marginBottom:'auto',width:'55%' ,marginLeft:'5px'}}><b style={{marginRight:'5px'}}>{props?.item?.paidDate? "Paid On: ":"Due On: "}</b>  {props?.item?.paidDate? milliToFormattedDate(props?.item?.paidDate -(new Date().getTimezoneOffset()*60000)) :milliToFormattedDate(props?.item?.dueDate -(new Date().getTimezoneOffset()*60000))}</Text> 
      <Text style={{marginTop:'auto',fontSize:theme?.isPastBreak?'12px':'15px',width:'auto' ,marginLeft:'auto',marginBottom:'auto'}}> <b style={{marginRight:'5px'}}>{props?.item?.paidDate? "Paid: ":"Total: "}</b>   ${ (props?.item?.amount/100).toFixed(2) }</Text>  
    </div>
  )
}

const dispatchToProps = dispatch =>{
    return { 
            setShowMenu:(item)=>dispatch(setShowMenu(item)),
            setShowModal:(item)=>dispatch(setShowModal(item)),
            setModalContent:(item)=>dispatch(setModalContent(item)),
            setContracts:(item)=>dispatch(setContracts(item)),
            setCompletedContracts:(item)=>dispatch(setCompletedContracts(item))
          }
} 

const mapStateTopProps = state =>{
    return {...state,...state.sys}
} 
export default connect(mapStateTopProps,dispatchToProps)(ContractCard)  