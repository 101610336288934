import React , {useState,useEffect} from 'react'
import {setShowMenu,setShowModal,setModalContent} from '../../../actions'; 
import { connect } from 'react-redux'; 
import Button from '../../../components/button/Button'
import Text from '../../../components/text/Text'
import Theme from '../../../components/theme/Theme'
import ConfirmContract from '../modals/ConfirmContract'; 

const ContractRequests = (props) => {
    const [payments,setPayments] = useState([])
    const theme = Theme()
    const getUpcomingPayments = () =>{
        let localpayments = []
        //logic to go through contracts and find which ones will have payments due next...
        //add first, if and in list is less than add one, if less than and lis count == 3, 
        // add to list sort list, then slice to 3 elements from 4.
        localpayments = [...props?.data]
        localpayments = localpayments.filter(item => { return item.certified === false})
        localpayments.sort((doc1,doc2)=>{return doc1.lastpaid - doc2.lastpaid})
        setPayments([...localpayments])
    }
    useEffect(()=>{
        getUpcomingPayments()
    },[props?.data,props?.auth?.contracts])
    useEffect(()=>{

    },[payments])
    const strucUI = (children,click, key)=>{
        return  <div key={key} onClick={(e)=>{click(e)}} style={{display:'flex',margin:'auto',width:'100%',marginLeft:'auto',marginTop:'10px',padding:'5px',paddingTop:'2px', backgroundColor:'rgba(0,0,0,0.03)',borderRadius:'8px',maxWidth:'90%'}}>
                    {children}
                </div>
     }
  return (<>
   {/* <Title style={{ marginBottom:'5px'}} data="Payment Requests" /> */}
    <div style={{width:'100%',height:'auto',display:'flex',marginBottom:'20px',flexDirection:'column',justifyContent:'center'}}>

        {payments.map((item)=>{ 
            return strucUI([<Text style={{marginRight:'0px',fontSize:theme?.isPastBreak?'12px':'14px' }}><b> New Request </b><br/>{item.bizName}</Text>,
            <Text style={{marginLeft:'5px',marginTop:'auto',marginRight:'auto',fontSize:theme?.isPastBreak?'12px':'14px'}}>${(item.total/100).toFixed(2)}</Text>,
            <Button click={()=>{ 
                    props?.setModalContent(<ConfirmContract resetUserData={props?.resetUserData} data={{...item}}  />)
                    props?.setShowModal(true)               
            }} hover={{borderBottom:'0px solid white',backgroundColor:theme?.colors?.secondary,color:theme?.colors?.button,boxShadow:'hidden'}} 
            style={{  padding:'3px',minWidth:'auto',width:'auto',height:'auto',borderRadius:'6px',minHeight:'auto',paddingLeft:'5px',paddingRight:'5px',margin:'auto',marginRight:'0px',borderBottom:'2px solid '+theme?.colors?.main  ,color:theme?.colors?.secondary,fontSize:'12px' }} name="Confirm"/>
            ],
                (e)=>{
                    props?.setModalContent(<ConfirmContract resetUserData={props?.resetUserData} data={item}  />)
                    props?.setShowModal(true)
                },
                item.id.substring(0,8))
        })}
    </div>
    </>
  )
}

 
const dispatchToProps = dispatch =>{
    return { 
            setShowMenu:(item)=>dispatch(setShowMenu(item)),
            setShowModal:(item)=>dispatch(setShowModal(item)),
            setModalContent:(item)=>dispatch(setModalContent(item)),
          }
} 

const mapStateTopProps = state =>{
    return {...state,...state.sys}
} 
export default connect(mapStateTopProps,dispatchToProps)(ContractRequests) 