import React, { useState ,useEffect} from 'react'
import {setShowMenu,setDoc,setShowModal,setModalContent} from '../../actions'; 
import { connect } from 'react-redux';  
import Theme from '../../components/theme/Theme';
import Types from '../../utilities/Types'
import Button from '../../components/button/Button';
import axios from 'axios';   
import PaymentAdder from '../../screens/userhome/modals/PaymentAdder';
import Card from '../../components/card/Card';

import { Circles } from  'react-loader-spinner'

const Cards = (props) => {
  const theme = Theme() 
  const [firstLoad,setFirstLoad] = useState(false)
  const [loading,setLoading] = useState(false)

  const [cards,setCards] = useState([])
  //getUserCards
  const getCards =  async () => {
    const tok = await props?.auth.auth.getIdToken(); 
    axios.post(Types.baseUrl+"getUserCards",{token:tok}).then(data=>{  
      if(data.data.status==='Success'){
        let sortcards = data.data.docs.sort((a,b)=>{
       
          return a['type'].localeCompare(b['type'])
        })
        setCards([...sortcards])
        props?.setCards([...sortcards])
        setFirstLoad(false) 
      } else { 
        setFirstLoad(false)
      }
    }).catch(e=>{

        setFirstLoad(false)
    })
  }
  
  useEffect(()=>{
    // getCards()
    setCards([...props?.auth?.cards])
  },[props?.auth.auth,props?.auth?.cards])
  useEffect(()=>{
    if(cards.length > 0){
      setFirstLoad(false)
    }

  },[cards])

  

  const addpaymentmethod = ()=>{props?.setModalContent(<PaymentAdder resetCards={()=>getCards()}/>);props?.setShowModal(true)}
  const getButton = () =>{
    // if( cards.length > 0) return null
    return <Button isDisabled={loading} hover={{ }} click={addpaymentmethod} style={{marginTop:'10px',padding:'4px',minWidth:'40px',maxWidth:'80px',marginBottom:'10px'}} name={'Add Card'} />
  }
  
  return (
    firstLoad ? <Circles
        height="40"
        width="40"
        color={theme?.colors?.main}
        ariaLabel="circles-loading"
        wrapperStyle={{margin:'auto',backgroundColor:'white',borderRadius:'25px',padding:'5px'}}
        wrapperClass=""
        visible={true}
      />:
      <div style={{transition:'all 500ms ease-in-out',width:'100%',margin:'auto' ,paddingTop:'10px',paddingBottom:'10px' ,marginTop:'0px',height:'auto',borderRadius:'18px',borderTopLeftRadius:'0px',borderTopRightRadius:'0px',marginBottom:'0px' ,backgroundColor:'white',display:'flex',boxShadow:'none',flexDirection:'column',...props?.style}}>
            {/* {props?.isSelect? null:<Title style={{marginTop:'0px',marginLeft:'2.5%',marginBottom:'0px'}} data="Payment Methods" />} */}
            <div style={{width:'90%',margin:'auto',borderRadius:'12px',minHeight:'0px',display:'block',flexDirection:'column',flexWrap:'wrap' }}>
            {cards.map((item,index)=>{
                return <Card selectedId={props?.selectedId} selectCard={props.selectCard} isSelect={props.isSelect} resetCards={()=>getCards()} auth={props?.auth?.auth} data={item} />
            })}
            </div>
            {getButton()}
    </div>
    
  )
}
 
const dispatchToProps = dispatch =>{
    return { 
            setShowMenu:(item)=>dispatch(setShowMenu(item)),
            setDoc:(item)=>dispatch(setDoc(item)),
            setShowModal:(item)=>dispatch(setShowModal(item)),
            setModalContent:(item)=>dispatch(setModalContent(item))
          }
} 

const mapStateTopProps = state =>{
    return {...state,...state.sys}
} 
export default connect(mapStateTopProps,dispatchToProps)(Cards)