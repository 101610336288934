// import { FETCH_SITE } from "../actions";

import { SET_AUTH, SET_CARDS, SET_COMPLETED_CONTRACTS, SET_CONTRACTS, SET_DOC, SET_NOTIFICATIONS, SET_PENDING_CONTRACTS } from "../actions";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = {auth:null,doc:null,cards:[],notifications:[],contracts:[],completedcontracts:[],pendingcontracts:[]}, action)=>{
    switch(action.type){
        case SET_AUTH:
            return {...state,auth:action.payload};
        case SET_DOC:
            return {...state,doc:action.payload};
        case SET_CARDS:
            return {...state,cards:action.payload};
        case SET_CONTRACTS:
            return {...state,contracts:action.payload};
        case SET_COMPLETED_CONTRACTS:
            return {...state,completedcontracts:action.payload};
        case SET_PENDING_CONTRACTS:
            return {...state,pendingcontracts:action.payload};
        case SET_NOTIFICATIONS:
            return {...state,notifications:action.payload};
        default:
            return state;
    }
}