import React ,{useEffect,useState} from 'react'
import {setShowMenu,setContracts} from '../../../actions'; 
import { connect } from 'react-redux';  
import Text from '../../../components/text/Text'
import {setShowModal,setModalStack} from "../../../actions/index"
import Button from '../../../components/button/Button';
import Theme from '../../../components/theme/Theme';
import { setAuth } from '../../../actions'; 
import Types from '../../../utilities/Types';
import Cards from '../../../components/card/Cards';
import axios from 'axios';
import Header from '../../../components/header/Header';
import { getTotalFees, getTotalPaid, getWeeklyPaymentTotals } from '../../../utilities';

const ConfirmContract = (props) => {
const theme = Theme()  
const [doc,setDoc] = useState({})
const [card,selectCard] = useState('')
const [sending,setSending] = useState(false)  


const confirmContract = async ()=> {
  setSending(true)
  const tok = await props?.auth.auth.getIdToken(); 
  axios.post(Types.baseUrl+'confirmContract',{doc:{contractKey:doc.id,...doc,cpid:card },offset:(new Date().getTimezoneOffset()*60000),token:tok}).then(res=>{ 
          alert(res.data.message) 
          //update ui here
          if(res.data.status === 'Success'){  
           
             
             if(props?.getNotes) {
              props?.getNotes()
             }
            } 

          setSending(false) 
          props?.setShowModal(false)
          props?.setModalStack(null)
        }).catch(e=>{
          alert(e.message)
          setSending(false)
        })
   


}

useEffect(()=>{
   
      setDoc({...props?.data})
    
},[ ])

useEffect(()=>{ 
},[doc])
 
   
const styletext = {margin:'auto',marginTop:'12px',marginLeft:'5%' ,color:theme?.colors?.lighttext}
  return (
    <div style={{width:'100%',paddingBottom:'0px',minHeight:'100%',display:'flex',display:'block' ,flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
         <Header style={{...theme.modalHeader,marginBottom:'0px'}} data="Confrim Contract" /> 
          <Text style={{...styletext,fontSize:'20px',marginTop:'8px'}} ><b>{ doc?.bizName}</b><br/></Text>
          <div style={{backgroundColor:'rgba(0,0,0,0.02)',border:'1px whitesmoke solid',borderRadius:'12px',borderTopLeftRadius:"12px",borderTopRightRadius:'12px',width:'90%',margin:'auto',marginLeft:'5%',marginTop:'10px',marginBottom:'5px',paddingBottom:'5px'}}>
            
          <Text style={{...styletext}} ><b>Customer Name </b> <br/>{doc['CustomerName']}</Text>
          <Text style={{...styletext}} ><b>Description </b> <br/>{doc['description']}</Text>
          <div style={{display:'flex',flexWrap:'wrap'}}>
          <Text style={{...styletext}} ><b>Total</b> <br/>${(parseFloat(doc['total'])/100).toFixed(2)}</Text>
          <Text style={{...styletext}} ><b>Total Fees </b> <br/>{getTotalFees( doc['total'])}</Text>
          <Text style={{...styletext}} ><b>Total Paid </b> <br/>{getTotalPaid( doc['total'])}</Text>
          </div>
          <Text style={{...styletext}} ><b>Payment Plan <br/></b> 4 bi-weekly payments   </Text>
          <Text style={{...styletext}} >  Payment 1 <i>{getWeeklyPaymentTotals(doc)[0]} </i> </Text>

          <Text style={{...styletext}} >  Payment 2 <i>{getWeeklyPaymentTotals(doc)[1]} </i> </Text>

          <Text style={{...styletext}} >  Payment 3 <i>{getWeeklyPaymentTotals(doc)[2]} </i> </Text>

          <Text style={{...styletext}} >  Payment 4 <i>{getWeeklyPaymentTotals(doc)[3]} </i>  </Text>
          <Text style={{...styletext,fontSize:'12px'}} > Plus 03.9% and 50 cents per transaction.</Text>
          </div>
          {card === '' ? <Text style={{marginTop:'20px',fontWeight:'900',width:'100%',textAlign:'center',marginBottom:'0px'}} data="Select a payment method to continue."/> :
          <Button isDisabled={sending}
            click={()=>{confirmContract()}} 
            hover={{backgroundColor:theme.colors.secondary,color:theme?.colors?.button  }} 
            style={{color:theme.colors.secondary   ,margin:'auto',boxShadow:'0px 0px 3px '+theme.colors.main,fontWeight:'900',minWidth:'100px',maxWidth:'200px',height:'30px',marginBottom:'16px',marginTop:'16px',paddingLeft:'5px',paddingRight:'5px'}}
            name="Accept Payment Plan"/> }
          <Cards selectCard={selectCard} selectedId={card} isSelect={true} /> 
          
    </div>
  )
}
 
const dispatchToProps = dispatch =>{
    return { 
            setShowMenu:(item)=>dispatch(setShowMenu(item)),
            setShowModal:(item)=>dispatch(setShowModal(item)),
            setAuth:(item)=>dispatch(setAuth(item)),
            setModalStack:(item)=>dispatch(setModalStack(item)),
            setContracts:(item)=>dispatch(setContracts(item))
          }
} 

const mapStateTopProps = state =>{
    return {...state,...state.sys,...state.firebase}
} 
export default connect(mapStateTopProps,dispatchToProps)(ConfirmContract)