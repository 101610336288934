import axios from 'axios'
import React from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'
import Types from '../../utilities/Types'
import Text from '../text/Text'
import Theme from '../theme/Theme'
import {FaCcDiscover,FaCcMastercard,FaCcVisa,FaCcAmex} from 'react-icons/fa'

const Card = (props) => {
    const theme = Theme()
    const styler ={marginRight:'5px',fontSize:'28px',width:'auto',color:theme?.colors?.button}
    const getLogo = () =>{
      switch(props?.data?.type){
        case 'discover':
          return <FaCcDiscover style={styler}/>
        case 'mastercard':
          return <FaCcMastercard style={styler}/>
        case 'visa':
          return <FaCcVisa style={styler}/>
        case 'amex':
          return <FaCcAmex style={styler}/>
        default:
        break; 
          }
    }
    const selected = ()=>{
      if(props?.isSelect){ 
        if(props.data.id === props.selectedId){
          props.selectCard('')
        }else{
          props.selectCard(props.data?.id)
        }  
      }
    }
  return (
    <div onClick={()=>{selected()}}   style={{borderBottom:'.5px solid grey',cursor:'pointer',boxShadow:props?.isSelect&&props?.selectedId===props.data.id?theme.shadow:'none',borderRadius:props?.isSelect&&props?.selectedId===props.data.id?'6px':'0px',transition:'all 00ms ease-in-out',width:theme?.isPastBreak? '90%':'350px',margin:'auto',marginLeft:'0px',marginTop:'5px',marginBottom:'5px',display:'flex',position:'relative',padding:'4px' ,minHeight:'auto'}}>
         {getLogo()}
         <Text style={{margin:'5px'}} ><b>&#183;&#183;&#183;&#183;&#183;&#183;&#183;&#183;&#183;&#183;&#183;&#183;</b>{props?.data?.last4}</Text> 
         <Text style={{margin:'5px'}} > {props?.data?.exmonth}/{props?.data?.exyear}</Text> 
         {props.isSelect ? null  :<AiFillCloseCircle onClick={async(e)=>{
          let confirm = window.confirm("Are you sure you wish to delete this card? Any contracts already connected with this card will continute to use this card, you will need to manually change the card on a contract if you wish to change cards.");
          if(confirm){

            const tok = await props?.auth.getIdToken();  
            axios.post(Types.baseUrl+"deleteUserCard",{token:tok,id:props?.data?.id}).then(item=>{
                if(item.data.status === "Success"){
                 
                  props?.resetCards()
                }else{
                    
                }
            }).catch(e=>{

            })
          }
         }} style={{position:'absolute',top:'30%',right:'3',color:theme.colors.button}}/>}
         
    </div>
  )
}

export default Card