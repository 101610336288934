 
import { getFunctions, httpsCallable, } from "firebase/functions";
import { getAuth, onAuthStateChanged,signInWithEmailAndPassword,createUserWithEmailAndPassword,updateProfile,signInWithCustomToken ,sendPasswordResetEmail,deleteUser,signOut} from "firebase/auth";
import { getFirestore, collection,deleteDoc, query,orderBy, limit,where, getDocs,doc, setDoc,getDoc,addDoc ,onSnapshot,updateDoc,connectFirestoreEmulator,runTransaction} from "firebase/firestore";
import { initializeApp } from "firebase/app" 
import { getAnalytics } from "firebase/analytics";
import Types from "./Types";
 
function GetFirebase  (data) {  
  let app = null
  if(data){
    app = initializeApp({
      ...data
    },"customaccount"+data.apiKey+"");
  }  else {
    app = initializeApp({
                          ...Types.baseFirebaseServicer
                        })
  }
  const auth = getAuth(app);
  const analytics = getAnalytics(app) 
  const functions = getFunctions(app); 
  const db = getFirestore(app);    
  const myGetFunction = async (funcName) =>{ 
    const fire =  fireLocal
    const functions = fire.functions;
    const addMessage = fire.httpsCallable(functions, funcName);
    return addMessage
  } 
  const mySetDoc = async (doc,collection) => {
    const fire =  fireLocal  
    const docRef = await fire.addDoc(fire.collection(fire.firestore, collection), {
                  ...doc
                });
                if(docRef && docRef.id){ 
                  return   docRef.id
                }else {
                  return false
                }
    } 
    const mySetDocWithName = async (doc,collection,name) => {
      const fire =  fireLocal
      const docin = await fire.setDoc(fire.doc(fire.firestore,collection, name), {...doc});  
      if(docin && docin.id){ 
        return   {...docin,...doc,id:docin.id}
      }else {
        return false
      }
      
      } 
    const myUpdateDoc = async (docName,collectionName,updateVals) => {
        const fire =  fireLocal   
        const docRef = doc(fire.firestore, collectionName, docName); 
        // Set the "capital" field of the city 'DC'
        const returner =  await fire.updateDoc(docRef, {
          ...updateVals
        }).then(()=>{ return true }).catch(e=>{return false });
         
        return returner
    }
  const mySignOut= async()=>{
    signOut(auth)
  }
  const myRunTransactionGeneral = async (collection,docName,updateVal, adSub) =>{
    const fire = await GetFirebase() 
    try {
    const sfDocRef = await fire.doc(fire.firestore, collection,docName);
    await fire.runTransaction(fire.firestore, async (transaction) => {
      const sfDoc = await transaction.get(sfDocRef);
      if (!sfDoc.exists()) {
        throw "Document does not exist!";
      }
  
      let updateValer = sfDoc.data()[updateVal];
      
      if(!updateValer){
        updateValer = adSub
      } else {
        updateValer = updateValer + adSub
      } 
      transaction.update(sfDocRef, { [updateVal]:updateValer });
    }); 
  } catch (e) {
    console.log("Transaction failed: ", e);
  }
       
} 
    const resetPassword = async (email)=>{
            const fire =  fireLocal  
            const auth = fire.auth
            fire.sendPasswordResetEmail(auth, email)
              .then(() => {
                // Password reset email sent!
                alert("Password Reset Sent!")
                // ..
              })
              .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // ..
              });
    }
    
    const myGetDoc = async (docName,collectionName) => {
      const fire =  fireLocal
      const docRef = doc(fire.firestore, collectionName, docName);
      const docSnap = await getDoc(docRef); 
      if (docSnap.exists()) { 
        return {...docSnap.data(),id:docSnap.id}
      } else { 
        return null
      }
    }
   
    const myGetDocWhereOrderBy = async (collectionName,searchClause,searchQuerry,order) => {
      const fire =  fireLocal
      const q = fire.query(fire.collection(fire.firestore, collectionName), fire.where(searchClause, "==", searchQuerry), fire.where(order, "==", false) );
      const querySnapshot = await fire.getDocs(q);
      let docs = []
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots 
          docs = [...docs,{...doc.data(),id:doc.id}]
        
      });  
      return docs
    }
    const myGetDocOrderBy = async (collectionName,orderBy,orderDir) => {
      const fire =  fireLocal 
      const q = fire.query(fire.collection(fire.firestore, collectionName),   fire.orderBy(orderBy, orderDir) );
      const querySnapshot = await fire.getDocs(q);
      let docs = []
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots 
          docs = [...docs,{...doc.data(),id:doc.id}]
        
      });  
      return docs
    }
    const myGetDocWhere = async (collectionName,searchClause,searchQuerry) => {
      const fire =  fireLocal
      const q = fire.query(fire.collection(fire.firestore, collectionName), fire.where(searchClause, "==", searchQuerry));
      const querySnapshot = await fire.getDocs(q);
      let docs = []
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots 
          docs = [...docs,{...doc.data(),id:doc.id}]
        
      }); 
      return docs
    }
    const myGetDocs = async (collectionName) => {
      const fire =  fireLocal
      const q = fire.query(fire.collection(fire.firestore, collectionName));
      const querySnapshot = await fire.getDocs(q);
      let docs = []
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots 
          docs = [...docs,{...doc.data(),id:doc.id}]
        
      }); 
      return docs
    }
    const myDeleteDoc = async (docName,docLoc) =>{
      console.log(" doc loc  ",docLoc)
      console.log(" doc name ",docName)
      const fire =  fireLocal
      await fire.deleteDoc(fire.doc(fire.firestore, docLoc, docName)).then(doc=>{
        console.log("deleted")
      }).catch(e=>{
        console.log("error deleting ",e)
      });
    
    }
    const myGetToken = async () =>{
      const fire =  fireLocal
      const token =  await fire.auth.currentUser.getIdToken() 
      return token
    }
    
    const myGetListenerUser = async (collectionName,setDocs,userId) => {
      const fire =  fireLocal 
      const q = await fire.query(fire.collection(fire.firestore,collectionName),fire.where("userKey",'==',userId));
      const unsubscribe =  await fire.onSnapshot(q, (querySnapshot) => {
        const docs = [];
        querySnapshot.forEach((doc) => { 
            docs.push({...doc.data(),id:doc.id});
        }); 
        if(setDocs){
          setDocs([...docs])
        }
        return unsubscribe
        
        
      }); 
      return unsubscribe
    }

    const myGetListenerService = async (collectionName,setDocs,userId) => {
      const fire =  fireLocal 
      const q = await fire.query(fire.collection(fire.firestore,collectionName),fire.where("servicerKey",'==',userId));
      const unsubscribe =  await fire.onSnapshot(q, (querySnapshot) => {
        const docs = [];
        querySnapshot.forEach((doc) => { 
            docs.push({...doc.data(),id:doc.id});
        }); 
        if(setDocs){
          setDocs([...docs])
        }
        return unsubscribe
        
        
      }); 
      return unsubscribe
    }
    const myGetListenerOrderByTimeStamp = async (collectionName,setDocs) => {
      const fire =  fireLocal 
      const q = await fire.query(fire.collection(fire.firestore,collectionName),fire.orderBy("milliseconds",'asc'));
      const unsubscribe =  await fire.onSnapshot(q, (querySnapshot) => {
        const docs = [];
        querySnapshot.forEach((doc) => {
            docs.push({...doc.data(),id:doc.id});
        }); 
        if(setDocs){
          setDocs([...docs])
        }
        return unsubscribe
        
        
      }); 
      return unsubscribe
    }
    const myGetListenerOrderVar = async (collectionName,setDocs,orderBy,ascdsc) => {
      const fire =  fireLocal  
      const q = await fire.query(fire.collection(fire.firestore,collectionName),fire.orderBy(orderBy,ascdsc));
      const unsubscribe =  await fire.onSnapshot(q, (querySnapshot) => {
        const docs = []; 
        querySnapshot.forEach((doc) => { 
            docs.push({...doc.data(),id:doc.id});
        }); 
        if(setDocs){
          setDocs([...docs]) 
        }  
        return unsubscribe
        
        
      }); 
      return unsubscribe
    }
    
    const myGetListenerNoOrder = async (collectionName,setDocs) => {
      const fire =  fireLocal 
      const q = await fire.query(fire.collection(fire.firestore,collectionName) );
      const unsubscribe =  await fire.onSnapshot(q, (querySnapshot) => {
        const docs = [];
        querySnapshot.forEach((doc) => {
            docs.push({...doc.data(),id:doc.id});
        }); 
        if(setDocs){ 
          setDocs([...docs])
        }
        return unsubscribe
        
        
      }); 
      return unsubscribe
    }
    const myFunction = async (funcName,data,successFunc,errorFunc) =>{ 
        const fire =  fireLocal 
        const functions = fire.functions;
        const addMessage = fire.httpsCallable(functions, funcName);
        addMessage(data)
          .then((result) => {
            // Read result of the Cloud Function.
            /** @type {any} */
            const data = result.data;
            successFunc(data) 
          }).error(e=>{
            errorFunc(e)
          });
    }
    const mySignInWithToken=async(token,callback)=>{
          const fire =  fireLocal  
          await fire.signInWithCustomToken(fire.auth,token).then(userDoc=>{
            callback(userDoc)
          }).catch(e=>{
            callback(null,e)
          })
    }


    const fireLocal = {mySignOut:mySignOut,myGetListenerService:myGetListenerService,signInWithCustomToken:signInWithCustomToken,mySignInWithToken:mySignInWithToken,mySetDoc:mySetDoc,myGetListenerOrderByTimeStamp:myGetListenerOrderByTimeStamp,runTransaction:runTransaction,myRunTransactionGeneral:myRunTransactionGeneral,myGetListenerOrderVar:myGetListenerOrderVar,myGetToken:myGetToken,myGetDocOrderBy:myGetDocOrderBy,myGetDocs:myGetDocs,mySetDocWithName:mySetDocWithName,deleteUser:deleteUser,myGetDocWhereOrderBy:myGetDocWhereOrderBy,myGetListenerNoOrder:myGetListenerNoOrder,myDeleteDoc:myDeleteDoc,myGetFunction:myGetFunction,myFunction:myFunction,myUpdateDoc:myUpdateDoc,myGetListenerUser:myGetListenerUser,myGetDocWhere:myGetDocWhere,resetPassword:resetPassword,myGetDoc:myGetDoc,functions:functions,sendPasswordResetEmail:sendPasswordResetEmail,doc:doc,addDoc:addDoc,limit:limit,orderBy:orderBy, deleteDoc:deleteDoc,onSnapshot:onSnapshot,updateDoc:updateDoc,getDoc:getDoc,setDoc:setDoc,httpsCallable:httpsCallable,updateProfile:updateProfile,onAuthStateChanged:onAuthStateChanged,signInWithEmailAndPassword:signInWithEmailAndPassword,firestore:db,auth:auth,collection:collection,query:query,where:where,getDocs:getDocs,createUserWithEmailAndPassword:createUserWithEmailAndPassword};
  return fireLocal;
}  
export default (GetFirebase);