import React,{useEffect} from 'react'
import {setShowMenu,setModalContent,setShowModal, setSiteNav,setContracts, setUserNav,setAuth,setDoc} from '../../actions'; 
import { connect } from 'react-redux';  
import Theme from '../../components/theme/Theme'; 
import {userroutes} from '../../components/nav/routes'
import {RiHome4Line, RiAccountPinCircleLine} from 'react-icons/ri'
import {BiPurchaseTagAlt, BiHelpCircle,BiLogOutCircle} from 'react-icons/bi'
import {Link} from '../../components/nav/Link'
import Main from './views/Main'; 
import Payments from './views/Payments'
import Purchases from './views/Purchases';
import Settings from './views/Settings';
import SettingsBuisiness from './views/SettingsBuisiness';
import Contracts from './views/Contracts'; 
import {AiOutlinePlusCircle} from 'react-icons/ai'
import Header from '../../components/header/Header';
import { Circles } from 'react-loader-spinner';
import CreateContract from './views/CreateContract' 
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Types from '../../utilities/Types';
import { logout } from '../../utilities';
import NameLogo from '../../components/nav/NameLogo';
const UserHome = (props) => {  
  const theme = Theme() 

  const nav = useNavigate()

  useEffect(()=>{
    window.scroll(0,0)
  },[props?.userNav])
  
  useEffect(()=>{
    
  },[props?.auth?.doc,props?.auth?.contracts])

  const checkFunc = async ()=>{
    console.log("checking")
    await axios.get(Types.baseUrl+"check24Hour").then(item=>{
      console.log(item)

    }).catch(e=>{
      console.log(e.message)
    })
  }
  const logoutFunc = ()=>{logout(props,nav)}

  const getStylesSideNav = (name,overrides)=>{
    return {marginTop:'5px',minWidth:'200px',marginBottom:'5px',marginLeft:'auto',marginRight:'auto',width:'`auto`',fontWeight:'900' ,backgroundColor:props?.userNav ===name ? theme.colors.button :'transparent',boxShadow:props?.userNav ===name ?'0px 0px 10px '+theme.colors.main:'none',padding:props?.userNav ===name ?"6px":"3px",color:props?.userNav ===name ?theme.colors.secondary:theme.colors.lighttext,borderRadius:'8px',transition:'all ease-in-out 250ms',...overrides}
  }
   
  const getUI = () =>{
    return <div style={{width:'100%' ,display:'flex',flexWrap:'wrap'  }}>
              {theme?.isPastBreak?null:<div style={{width:theme?.isPastBreak?'90%' :'15%',minWidth:'200px',backgroundColor:''   ,height:theme?.isPastBreak?'10vh':'100vh',marginTop:'0px',display:'flex',flexDirection:theme?.isPastBreak?'row':'column',marginRight:'0px',marginLeft:'auto'}}>
                  <NameLogo style={{marginTop:'25px',fontSize:'32px',minWidth:'200px',marginBottom:'0px',marginLeft:'0px',marginRight:'auto',width:'`auto`',fontWeight:'900'  ,borderRadius:'6px',transition:'all ease-in-out 250ms'}}/> 
                  <Link icon={<RiHome4Line style={{marginRight:'5px',marginLeft:'0px'}}/>} click={()=>props?.setUserNav(userroutes.myDyvy)} style={getStylesSideNav(userroutes.myDyvy,{marginTop:'25px'})}  link={userroutes.myDyvy} />
                  {/* <Link icon={<RiMoneyDollarCircleLine style={{marginRight:'5px'}}/>} click={()=>props?.setUserNav(userroutes.upcoming)} style={{marginTop:'0px',marginBottom:'15px',minWidth:'200px',marginLeft:'auto',marginRight:'auto',width:'auto',fontWeight:'900',borderBottom:props?.userNav ===userroutes.upcoming ? '1px solid '+theme.colors.main :'none',backgroundColor:props?.userNav ===userroutes.upcoming ? 'white' :'transparent',borderRadius:'6px',transition:'all ease-in-out 250ms'}}  link={userroutes.upcoming} /> */}
                  {props?.auth?.doc?.type === 'user'?<Link icon={<BiPurchaseTagAlt style={{marginRight:'5px'}}/>} click={()=>props?.setUserNav(userroutes.purchases)} style={getStylesSideNav(userroutes.purchases)}  link={userroutes.purchases} />:<Link icon={<BiPurchaseTagAlt style={{marginRight:'5px'}}/>} click={()=>props?.setUserNav(userroutes.contracts)} style={getStylesSideNav(userroutes.contracts)}  link={userroutes.contracts} />}
                  {props?.auth?.doc?.type === 'user'?<Link icon={<RiAccountPinCircleLine style={{marginRight:'5px'}}/>} click={()=>props?.setUserNav(userroutes.acct)} style={getStylesSideNav(userroutes.acct)} link={userroutes.acct} /> :<Link icon={<RiAccountPinCircleLine style={{marginRight:'5px'}}/>} click={()=>props?.setUserNav(userroutes.biz)} style={getStylesSideNav(userroutes.biz)} link={userroutes.biz} />}
                  {props?.auth?.doc?.type === 'user'?null:<Link icon={<AiOutlinePlusCircle style={{marginRight:'5px'}}/>} click={()=>props?.setUserNav(userroutes.createContract)} style={getStylesSideNav(userroutes.createContract)} link={userroutes.createContract} />}
                  <Link icon={<BiHelpCircle style={{marginRight:'5px'}}/>}  style={getStylesSideNav(userroutes.help)}  link={userroutes.help} />  
                  <Link icon={<BiLogOutCircle style={{marginRight:'5px',color:'black'}}/>} click={()=>{logoutFunc()}}  style={{marginTop:'auto',marginBottom:'5vh',color:'black' ,minWidth:'200px',marginLeft:'auto',marginRight:'auto',width:'auto',fontWeight:'900'}}  link={'Logout'} /> 
              </div>}
              <div style={{width:theme?.isPastBreak?'100%':'65%',margin:'auto' ,marginLeft:theme?.isPastBreak?'auto':'auto',height:'93vh'  ,padding:theme?.isPastBreak?'0px':'2%',marginTop:theme?.isPastBreak?'0px':'60px'   }}>
                  {props?.userNav ===  userroutes.myDyvy ? <Main key="main1" resetUserData={props?.resetUserData}/> : null}
                  {props?.userNav === userroutes.upcoming ? <Payments  key="main2" resetUserData={props?.resetUserData}/> : null}
                  {props?.userNav === userroutes.purchases ? <Purchases  key="main3" resetUserData={props?.resetUserData}/> : null}
                  {props?.userNav ===  userroutes.acct ? <Settings  key="main4" resetUserData={props?.resetUserData}/> : null}
                  {props?.userNav ===  userroutes.biz ? <SettingsBuisiness  key="main5" resetUserData={props?.resetUserData}/> : null}
                  {props?.userNav ===  userroutes.contracts ? <Contracts  key="main6" resetUserData={props?.resetUserData}/> : null}
                  {props?.userNav === userroutes.createContract && props?.auth?.doc?.type !== 'user' ? <CreateContract  key="main7" resetUserData={props?.resetUserData}/> :null}
              </div>
           </div>
  }
  return (
    <div style={{width:'100%',height:'100vh' ,backgroundColor:'whitesmoke',overflowX:'hidden',display:'flex',flexDirection:'row',justifyContent:'center'}}>
         {props?.auth?.doc === null ?  <Header style={{marginTop:'20vh' ,zIndex:'400',display:'flex',flexDirection:'column'}} data="Loading user data, hang tight."><br/> <Circles
                  height="40"
                  width="40"
                  color={theme?.colors?.button}
                  ariaLabel="circles-loading"
                  wrapperStyle={{margin:'auto',backgroundColor:'white',borderRadius:'25px',padding:'5px',marginTop:'20px'}}
                  wrapperClass=""
                  visible={true}
                /> </Header> :getUI()}
    </div>
  )
}
 
const dispatchToProps = dispatch =>{
    return { 
            setShowMenu:(item)=>dispatch(setShowMenu(item)),
            setSiteNav:(item)=>dispatch(setSiteNav(item)),
            setUserNav:(item)=>dispatch(setUserNav(item)),
            setModalContent:(item)=>dispatch(setModalContent(item)),
            setShowModal:(item)=>dispatch(setShowModal(item)),
            setAuth:(item)=>dispatch(setAuth(item)), 
            setDoc:(item)=>dispatch(setDoc(item)), 
            setContracts:(item)=>dispatch(setContracts(item))
          }
} 

const mapStateTopProps = state =>{
    return {...state,...state.sys}
} 
export default connect(mapStateTopProps,dispatchToProps)(UserHome)