import React, { useEffect, useState } from 'react'
import {setShowMenu} from '../../../actions'; 
import { connect } from 'react-redux';   
import Selector from '../../../components/selector/Selector';
import MainDiv from '../components/MainDiv';
import History from '../components/History';
import UpcomingPayments from '../components/UpcomingPayments';
import Header from '../../../components/header/Header'; 
import Title from '../../../components/title/Title';
import Types from '../../../utilities/Types';
import axios from 'axios';

const Contracts = (props) => { 
  const [pending,setPending] = useState('')
  const [balance,setBalance] = useState('') 
  const getBalance = async () => {
    //get use doc logic here 
    let tok = await props?.auth?.auth?.getIdToken();
    await axios.post(Types.baseUrl+"getUserBalance",{token:tok}).then(async data=>{  
     setPending(parseFloat((data?.data?.data?.pending[0]?.amount)/100||0))
     setBalance(parseFloat((data?.data?.data?.available[0]?.amount)/100)||0)
     
 });
}
useEffect(()=>{
  getBalance()
},[])
  return (
    <MainDiv>
         <Selector key="contracts" title={<div style={{width:'100%',display:'flex'}}><Header style={{ marginLeft:'10px',marginTop:'auto',marginBottom:'auto',marginRight:'10px' }}>Contracts</Header> <Title style={{marginLeft:'auto',marginTop:'auto',fontSize:'16px' ,marginBottom:'auto',marginRight:'10px' }}> Available ${balance} <br/>Pending ${pending}</Title></div>} items={[{link:'Active',screen:<History title="Active Contracts" data={props?.auth?.contracts}/> }, {link:'Upcoming Payments',screen:<UpcomingPayments  data={props?.auth?.contracts}/>},{link:'Pending',screen:<History title="Pending Contracts" pending  data={props?.auth?.contracts}/>}, {link:'History',screen:<History title="Payment History"  data={props?.auth?.completedcontracts}/>},]}/>
    </MainDiv>
  )
}
 
const dispatchToProps = dispatch =>{
    return { 
            setShowMenu:(item)=>dispatch(setShowMenu(item))
          }
} 

const mapStateTopProps = state =>{
    return {...state,...state.sys}
} 
export default connect(mapStateTopProps,dispatchToProps)(Contracts)