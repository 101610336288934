import { combineReducers } from "redux";
import userReducer from "./userReducer";
import authReducer from "./authReducer";
import systemReducer from "./systemReducer";
import firebaseReducer from "./firebaseReducer";
export default combineReducers({
    user:userReducer,
    auth:authReducer,
    sys:systemReducer,
    firebase:firebaseReducer
})