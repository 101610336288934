import React from 'react'
import {setShowMenu} from '../actions'; 
import Theme from '../components/theme/Theme';
import { connect } from 'react-redux'; 
import Header from '../components/header/Header';
import Title from '../components/title/Title'; 
import Button from '../components/button/Button';
import SignUp from '../modals/SignUp'; 
import { setShowModal,setModalContent } from '../actions'; 
import BottomBar from '../components/nav/BottomBar';
import DropDownBox from '../components/DropDownBox/DropDownBox';
import HowItWorks from '../components/HowItWorks/HowItWorks';
import Iphone from '../assets/iphone.png'
import IphoneBiz from '../assets/iphoneBiz.png'
import IphoneAccept from '../assets/iphoneAccept.png' 
const Home = (props) => {
  const theme = Theme();

  const signupFunc = (isProvider)=>{props?.setModalContent(<SignUp isProvider={isProvider}/>);props?.setShowModal(true)}
  return (
    <div style={{width:'100%',backgroundColor:'white',overflowX:"hidden"   ,display:'flex',flexDirection:'column'}}> 
        <div style={{width:'100vw',display:'flex',flexDirection:'column',position:'relative',minHeight:theme?.isPastBreak? "350px" :'400px',paddingTop:'2vw',paddingBottom:'3vw',overflow:'hidden',flexWrap:'wrap-reverse',margin:'auto'}}> 
            <div style={{ display:'flex',flexFlow:'column',position:'absolute',justifyContent:'center',alignItems:'center' ,top:0,left:0,bottom:0,right:0,backgroundColor:'rgba(255,255,255,0.40)',zIndex:'1' }}> 
                    <Header data="Payments made easy."  style={{margin:'auto',zIndex:'10',width:'90%'   ,padding:'0px',fontWeight:'900',marginBottom:'0vw',textShadow:'0px 0px 50px white',marginTop:'auto',fontSize:theme?.isPastBreak?'34px':'65px',textAlign:'left' }} />
                    <div style={{width:'100vw',zIndex:'10',margin:'auto', maxWidth:'90%',marginTop:'0px',marginBottom:"auto" }}>
                        <Title style={{margin:'auto',zIndex:'10' ,fontWeight:'200',boxShadow:'0px 0px 0px grey',marginTop:'3vw',marginRight:'20vw',padding:'0px' ,textAlign:'left',textShadow:'0px 0px 50px white' }} data="">Need to purchase a service, but want to pay in installments? Dyvyup makes it easy. Simply accept a contract with one of our service providers, connect a card, and click pay. We handle the rest, the remaining payments will be automatically charged when due.
                        <br/>
                        <br/>
                        Interested in becoming a service provider? Find our more below.
                        </Title>   
                    </div> 
              </div>
            <img src={Iphone} alt='Logo Iphone with site on browser.'   style={{width:'20vw',margin:'auto',position:'relative',marginLeft:'auto',marginRight:'4vw' ,zIndex:'0',borderRadius:'3vw'}} />
        </div>
        
        <div style={{width:'100%',backgroundColor:'whitesmoke', justifyContent:'center',paddingBottom: '10vw' ,paddingTop: '10vw' ,alignItems:'center',display:'flex',flexFlow:'column'}}>
           
                <div style={{display:'flex',flexDirection:'row',margin:'auto',flexWrap:'wrap',justifyContent:'center',alignItems:'center' ,width:'100%' }}> 
                      <img src={IphoneAccept} alt='Logo Image of financial payments.'   style={{width:theme?.isPastBreak?'50vw':'25vw',margin:'auto'  ,borderRadius:theme?.isPastBreak? "8vw" :'3.75vw',boxShadow:'0px 0px 4px rgba(0,0,0,0.2)'}} />
                      <div style={{width:theme?.isPastBreak? '90vw':'50vw',flexFlow:'column',justifyContent:'start',height: 'auto',display:'flex',margin:'auto'  }} >
                          <Header data="So how does it work?"  style={{margin:'auto',width:'100%'   ,padding:'8px',marginTop:theme?.isPastBreak ?'4vh' :'2vw',marginBottom:theme?.isPastBreak ?'1vh' :'2vw',fontWeight:'900' ,fontSize:theme?.isPastBreak?'28px':'50px',textAlign:'left' }} />
                          <div style={{width:'100%',paddingBottom:'2.5%',paddingTop:'1.5%',boxShadow:'0px 0px 2px rgba(0,0,0,0.25)',backgroundColor:'whitesmoke',display:'flex',flexWrap:'wrap',minHeight: 'auto',margin:'auto',flexDirection:'column' ,borderRadius:'12px'}}>
                            <DropDownBox  minHeight={500} maxHeight='auto' name={'Confirm a contract.'}>
                                <HowItWorks  text='Your service provider will send a contract for you to accept.'/> 
                            </DropDownBox> 
                            <DropDownBox  minHeight={500} maxHeight='auto' name={'Connect a card.'}>
                                <HowItWorks text='Connect your credit or debit card. Dont worry, we have you covered. We use industry standard cryptographic algorithms in addition to google security to keep you secure.'/> 
                            </DropDownBox> 
                            <DropDownBox  minHeight={500} maxHeight='auto' name={'Pay off the contract in 4 increments.'}> 
                              <HowItWorks text='The remaining 3 payments will be paid off with the connected card and you will receive an email receipt.'/> 
                            </DropDownBox> 
                          </div> 
                      </div>  
                </div> 
           
        </div>
        <div style={{width:'100%',paddingTop:'2vw',paddingBottom:'3vw' ,backgroundColor:theme?.colors?.button ,display:'flex',flexWrap:'wrap',minHeight:'30vh',margin:'auto',flexDirection:'column',marginTop:'0vw',marginBottom:'0vw',borderRadius:'0px'}}>
                    <Header data="Signup to buy from a buisiness now! " style={{margin:'auto',width:'auto'   ,padding:'8px',fontWeight:'900',marginBottom:'0px',fontSize:theme?.isPastBreak?'28px':'50px',textAlign:'center',color:theme?.colors?.secondary }} />
                            <Button click={(e)=>{
                              signupFunc()
                            }}   
                            
                            style={{  fontWeight:'900',backgroundColor:theme?.colors?.secondary,color:theme?.colors?.button,maxWidth:'auto',marginLeft:'auto',marginBottom:'auto',marginTop:'2vw'}}
                            name="Become a buyer" />
                </div>  
      <div style={{width:'100%',backgroundColor:'whitesmoke',justifyContent:'center',paddingBottom: '10vw' ,paddingTop: '10vw' ,alignItems:'center',display:'flex',flexFlow:'column'}}>              
        
                  <div style={{display:'flex',flexWrap:'wrap',margin:'auto'  ,justifyContent:'center',width:'100%' }}> 
                      <div style={{display:'flex',flexDirection:'row',margin:'auto',flexWrap:'wrap-reverse',justifyContent:'center' ,width:'100%'}}>
                          <div style={{width:theme?.isPastBreak? '90vw':'50vw',flexFlow:'column',justifyContent:'start',height: 'auto',display:'flex',margin:'auto'  }} >
                          <Header data="Why use DyvyUp for your service based buisiness?"  style={{margin:'auto',width:'auto',marginTop:theme?.isPastBreak ?'4vh' :'2vw',marginBottom:theme?.isPastBreak ?'1vh' :'2vw' ,padding:'8px',fontWeight:'900',fontSize:theme?.isPastBreak?'28px':'50px',textAlign:'left' }} />
                          <div style={{width:'100%' ,paddingBottom:'2.5%',paddingTop:'1.5%',boxShadow:'0px 0px 2px rgba(0,0,0,0.25)',backgroundColor:'whitesmoke',display:'flex',flexWrap:'wrap',minHeight:theme?.isPastBreak?'auto':'auto',margin:'auto',flexDirection:'column' ,borderRadius:'12px'}}>
                            <DropDownBox  minHeight={500} maxHeight='auto' name={'Increased sales.'}>
                                <HowItWorks number='' text='Service providers who use dyvy up may notice an increase in average customer billables. Sign up and get more revenue today.'/> 
                            </DropDownBox> 
                            <DropDownBox  minHeight={500} maxHeight='auto' name={'Easier payment collection.'}>
                                <HowItWorks number='' text='No need to keep recollecting payments. Sign a payment agreement and let us handle the rest.'/> 
                            </DropDownBox> 
                            <DropDownBox  minHeight={500} maxHeight='auto' name={'Smaller service fees.'}>
                                <HowItWorks number='' text='Less than 1%? That is correct, with us you pay a fraction of the cost of our competitors.'/> 
                            </DropDownBox> 
                            <DropDownBox  minHeight={500} maxHeight='auto' name={'We share the late fees.'}>
                                <HowItWorks number='' text='Unlike others, we split our late fees.'/> 
                            </DropDownBox> 
                            <DropDownBox  minHeight={500} maxHeight='auto' name={'How do I signup?'}>
                            <Button click={(e)=>{
                                    signupFunc(true)
                                  }}   
                                  style={{  fontWeight:'900',maxWidth:'auto',margin:'auto',marginTop:'8px'  }}
                                  name="Become a Service Provider" />
                            </DropDownBox> 
                          </div>
                              
                          </div>  
                              <img src={IphoneBiz} alt='Logo Image of financial payments.'   style={{width:theme?.isPastBreak?'50vw':'25vw',margin:'auto' ,borderRadius:theme?.isPastBreak? "8vw" :'3.75vw',boxShadow:'0px 0px 4px rgba(0,0,0,0.2)'}} />                      
                      </div>
                  </div>
           
        </div>
 
  
        

        <div style={{width:'100%',paddingTop:'2vw',paddingBottom:'3vw' ,backgroundColor:'white' ,display:'flex',flexWrap:'wrap',minHeight:'30vh',margin:'auto',flexDirection:'column',marginTop:'0vw',marginBottom:'0vw',borderRadius:'0px'}}>
    
            <Header data="Recieve payments for your service based business now. " style={{margin:'auto',width:'auto'   ,padding:'8px',fontWeight:'900',marginBottom:'0px',fontSize:theme?.isPastBreak?'28px':'50px',textAlign:'center' }} />
      
                  <Button click={(e)=>{
                    signupFunc(true)
                  }}   
                  style={{  fontWeight:'900',maxWidth:'auto',marginLeft:'auto' ,marginTop:'2vw'}}
                  name="Become a Service Provider" />
        </div> 
        <BottomBar/>
    </div>
  )
}
 
const dispatchToProps = dispatch =>{
    return { 
            setShowMenu:(item)=>dispatch(setShowMenu(item)),
            setModalContent:(item)=>dispatch(setModalContent(item)),
            setShowModal:(item)=>dispatch(setShowModal(item))
          }
} 

const mapStateTopProps = state =>{
    return {...state,...state.sys}
} 
export default connect(mapStateTopProps,dispatchToProps)(Home)