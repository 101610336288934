import {  SET_SAVING,SET_MODAL_STACK,SET_DEVICE_TYPE,   SET_MODAL_CONTENT, SET_SHOW_MODAL,  SET_SCROLL, SET_BOTTOM, SET_SHOW_MENU, SET_SEARCH_TEXT, SET_SITE_NAV, SET_USER_NAV, SET_USER_DATA} from "../actions";
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = {deviceType:'desktop',resetUserData:()=>{},siteNav:'Home',userNav:'My Dyvy Up',searchText:'',showMenu:false,scroll:false,bottom:true,saving:false,showModal:false,modalContent:null,modalBackStack:[]}, action)=>{
     
    switch(action.type){ 
        case SET_MODAL_CONTENT:  
            
            let newBackStack = [...state.modalBackStack]
            if(state.modalContent && action.payload !== null) newBackStack = [state.modalContent,...newBackStack]
            return {...state, modalContent:action.payload,modalBackStack:newBackStack} 
        case SET_MODAL_STACK: 
            let bstack = [...state.modalBackStack]
            let newCurrent = bstack.shift() 
            return {...state, modalContent:newCurrent,modalBackStack:bstack}
               
        case SET_SHOW_MODAL:
            return {...state, showModal:action.payload,modalBackStack:action.payload === true?state.modalBackStack:[]}

        case SET_SEARCH_TEXT:
            return {...state, searchText:action.payload}
            
        case SET_SAVING:
            return {...state, saving:action.payload}
         
        case SET_USER_DATA:
                return {...state, resetUserData:action.payload}
        
        case SET_SCROLL:
            return {...state, scroll:action.payload}
    
        case SET_SHOW_MENU:
            return {...state, showMenu:action.payload} 
            
        case SET_DEVICE_TYPE:
            return {...state, deviceType:action.payload}

        case SET_BOTTOM:
            return {...state, bottom:action.payload}
        
        case SET_SITE_NAV:
            return {...state, siteNav:action.payload}

        case SET_USER_NAV:
            return {...state, userNav:action.payload}
     
    

        default:
            return state;
    }

}