import React ,{useEffect,useState} from 'react'
import {setShowMenu} from '../actions'; 
import { connect } from 'react-redux';  
import { useNavigate } from 'react-router-dom'; 
import Text from '../components/text/Text'
import {setShowModal,setModalStack} from "../actions/index"
import Button from '../components/button/Button'; 
import { setAuth } from '../actions';
import InputComponent from '../components/input/InputComponent'; 
import axios from 'axios'
import Types from '../utilities/Types' 
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'; 
import NameLogo from '../components/nav/NameLogo';
import Theme from '../components/theme/Theme';
const SignUp = (props) => { 
const [user,setUser] = useState({email:'',pass:'',pass2:'',type:'user',name:''})
const [disabled,setDisabled] = useState(false)
  useEffect(()=>{
    if(props?.isProvider){
      setUser({...user,type:'provider'})
    }
  },[])
  const theme = Theme()
  const nav = useNavigate()
  const style ={width:"95%", 
  maxWidth:'95%',
  margin:'auto',
  marginLeft:'2.5% ',  
  marginTop:'1px', 
  backgroundColor:'rgba(255,255,255,0.7)'}
  const registerNow = () =>{
          let register = true
          let message = ""
          if(user.email === ''){ register =  false; message = "Please enter your email.\n"}
          if(user.name === ''){ register =  false; message = "Please enter your name.\n"}
          if(user.pass === '') { register =  false; message = message + "Please enter your password.\n"}
          if(user.pass2 === '') { register =  false; message = message + "Please re-enter your password.\n"}
          if(user.pass !== user.pass2) {register = false; message = message+ "Your passwords do not match.\n"}
          if(register){ 
            setDisabled(true)
              axios.post(Types.baseUrl+'register',user).then(res=>{
              
                if(res.data.status === 'Success'){
                  alert("User Register Success!\n"+res.data.message)
                  setDisabled(false)
                  props?.firebase?.mySignInWithToken(res.data.token,(user,error)=>{
                    if(error){
                      
                    }else{
                      
                      nav("/UserHome");
                      props?.setShowModal(false);
                      props?.setModalStack([]);
                    }
                  })
                }else{
                  alert(res.data.message)
                  setDisabled(false)
                }
                
              }).catch(e=>{
                alert("Error Registering...\n"+e.message)
                setDisabled(false)
              })
          }else{
            alert(message)
            setDisabled(false)

          }
  }
  return (
    <div style={{width:'100%',marginTop:'3px',borderRadius:'8px',minHeight:'500px', background:'white',height:'100%',display:'flex',flexDirection:'column'  }}>
         
          {/* <NameLogo style={{color:theme.colors.main,marginLeft:'auto',fontSize:'36px'}}/> */}
          <NameLogo style={{...theme.modalHeader,margin:'auto',marginLeft:'auto',width:'auto',marginBottom:'25px',marginTop:'15px' }} data="Dyvy Up"/>
       <Text style={{margin:'auto',marginLeft:'5%',marginTop:'0px'}} data="Enter your email." />
          <InputComponent style={style} label="Email" onChange={(e)=>{setUser({...user,email:e.target.value})}} />  
          <Text  style={{margin:'auto',marginLeft:'5%',marginTop:'10px'}} data="Enter your full legal name." />
          <InputComponent label="Username" style={style}   onChange={(e)=>{setUser({...user,name:e.target.value})}} />  
          
          
          <Text style={{margin:'auto',marginLeft:'5%',marginTop:'10px'}}  data="Enter your password." />
          <InputComponent style={style} label="Password" onChange={(e)=>{setUser({...user,pass:e.target.value})}} />  
          <Text style={{margin:'auto',marginLeft:'5%',marginTop:'10px'}} data="Verify your password." />
          <InputComponent style={style} label="Password" onChange={(e)=>{setUser({...user,pass2:e.target.value})}} /> 
          <Text style={{margin:'auto',marginLeft:'5%',marginTop:'20px'}}  data="Select Account Type." />
                 <FormControl   sx={{ ...style,backgroundColor:'transparent'}}>
                    <Select
                     sx={{ ...style}}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={user.type} 
                      onChange={(e)=>{setUser({...user,type:e.target.value})}}
                    >
                      <MenuItem value={"user"}>Regular User</MenuItem>
                      <MenuItem value={"provider"}>Service Provider</MenuItem>
                    </Select>
                 </FormControl>
                {user.type === 'provider'? <Text style={{margin:'auto',fontSize:'12px',fontWeight:'900',color:'rgba(0,0,0,0.9)'}}   data="Account for Selling a Service." />:<Text style={{margin:'auto',fontWeight:'900',fontSize:'12px',color:'rgba(0,0,0,0.9)'}}  data="Account for Buying a Service." />} 
          <Button isDisabled={disabled} click={()=> {registerNow()}}  
          style={{ marginTop:'30px',marginBottom:'10px' }}
          name="Signup"/>
        
    </div>
  )
}
 
const dispatchToProps = dispatch =>{
    return { 
            setShowMenu:(item)=>dispatch(setShowMenu(item)),
            setShowModal:(item)=>dispatch(setShowModal(item)),
            setAuth:(item)=>dispatch(setAuth(item)),
            setModalStack:(item)=>dispatch(setModalStack(item))
          }
} 

const mapStateTopProps = state =>{
    return {...state,...state.sys,...state.firebase}
} 
export default connect(mapStateTopProps,dispatchToProps)(SignUp)