import React, { useState } from 'react'
import {setShowMenu,setDoc,setShowModal,setModalContent} from '../../../actions'; 
import { connect } from 'react-redux';  
import InputComponent from '../../../components/input/InputComponent';
import Theme from '../../../components/theme/Theme';
import Types from '../../../utilities/Types'
import Button from '../../../components/button/Button';
import axios from 'axios'; 
import Text from '../../../components/text/Text'; 


const BuisinessEditor = (props) => {
  const theme = Theme() 
  const [changed,setChanged] = useState({})
  const [loading,setLoading] = useState(false) 

  const filterKeys = (key) => {
    let tobechanged = {...changed}
    delete tobechanged[key];
    setChanged({...tobechanged});
  }
  const checksame = (key,changed) =>{
    let toberemoved = {...changed}
      if(changed[key]===props?.auth?.doc[key]){ 
        delete toberemoved[key]
        setChanged({...toberemoved})
      } 
  }
  const changeuser = async () => {
    setLoading(true)
    const tok = await props?.auth.auth.getIdToken(); 
    axios.post(Types.baseUrl+"updateUser",{token:tok,doc:changed}).then(data=>{  
      if(data.data.status==='Success'){
        alert(data.data.message)
        props?.setDoc({...props?.auth?.doc,...changed})
        setChanged({})
        setLoading(false)
      } else {
        alert(data.data.message)
        setLoading(false)
      }

    }).catch(e=>{
      setLoading(false)
    })
  }
  
  return (
    <>
                           {/* <Title style={{marginTop:'5px',marginLeft:'2.5%',marginBottom:'0px'}} data="Buisiness Information" /> */}
                          {props?.auth?.doc ?Object.keys(props?.auth?.doc).map(item=>{
                              let itemnew = item.split('')
                              let firstletter = itemnew.splice(0,1)
                              if(item ==='type'|| item==='sid'|| item==='id')return null
                              return <div style={{width:'95%',maxWidth:'500px',margin:'auto',marginTop:'20px',marginLeft:'10px'}}> 
                                        <Text style={{fontSize:'14px',color:theme?.colors?.lightext}} data={firstletter.toString().toLocaleUpperCase()+itemnew.join('').toString()} />
                                        <InputComponent multiline={item === "Buisiness Summary" ?true:false} rows={item === "Buisiness Summary" ?4:1}  inputProps={item === "phone" ?{ maxLength: 10,inputMode: 'numeric' }:{}}  style={{width:'100%'}} defaultValue={props?.auth?.doc[item]} value={changed[item]} 
                                        onChange={(e)=>{
                                         
                                          if(e.target.value !== ''){
                                            if(item === "phone"){
                                              e.target.value =  e.target.value.replace(/[^0-9]/g, '')
                                              setChanged({...changed,[item]: e.target.value.replace(/[^0-9]/g, '')})
                                              checksame(item,{...changed,[item]: e.target.value.replace(/[^0-9]/g, '')})
                                            }else {
                                              setChanged({...changed,[item]:e.target.value})
                                              checksame(item,{...changed,[item]:e.target.value})
                                            }
                                            }
                                          else {
                                              filterKeys(item)
                                            }
                                            }}  />
                                      </div>
                          }):null} 
                           {Object.keys(changed).length > 0 ? <Button isDisabled={loading} click={changeuser}  name={'Save Changes'} /> :null}
        
                  </>    
  )
}
 
const dispatchToProps = dispatch =>{
    return { 
            setShowMenu:(item)=>dispatch(setShowMenu(item)),
            setDoc:(item)=>dispatch(setDoc(item)),
            setShowModal:(item)=>dispatch(setShowModal(item)),
            setModalContent:(item)=>dispatch(setModalContent(item))
          }
} 

const mapStateTopProps = state =>{
    return {...state,...state.sys}
} 
export default connect(mapStateTopProps,dispatchToProps)(BuisinessEditor)