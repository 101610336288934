import React from 'react'
import {setShowMenu} from '../../../actions'; 
import { connect } from 'react-redux';  
import Notifications from '../components/Notifications';
import Selector from '../../../components/selector/Selector';
import UpcomingPayments from '../components/UpcomingPayments';
import MainDiv from '../components/MainDiv';
 
const Main = (props) => { 
  let items = [{link:"Notifications",screen:<Notifications />}]
  if(props?.auth?.doc?.type === "user"){
    items = [...items, {link:"Upcoming Payments",screen:<UpcomingPayments  data={props?.auth?.contracts}/>}]
  }
  return (
    <MainDiv>
      <Selector key="mainkey" title={"Hello, "+ props?.auth?.doc?.name} items={items} />
    </MainDiv>
  )
}
 
const dispatchToProps = dispatch =>{
    return { 
            setShowMenu:(item)=>dispatch(setShowMenu(item))
          }
} 

const mapStateTopProps = state =>{
    return {...state,...state.sys}
} 
export default connect(mapStateTopProps,dispatchToProps)(Main)