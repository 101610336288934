import React  from 'react'
import {setShowMenu} from '../../../actions'; 
import { connect } from 'react-redux';  
import {setShowModal,setModalStack} from "../../../actions/index"  
import { setAuth } from '../../../actions'; 
import Selector from '../../../components/selector/Selector';
import ContractCreator from '../components/ContractCreator';
import MainDiv from '../components/MainDiv';


const CreateContract = (props) => { 


   
  return (
    <MainDiv><Selector key="createcontract" title="Create A Contract" items={[{link:'Create',screen:<ContractCreator/>}]} /></MainDiv>
  )
}
 
const dispatchToProps = dispatch =>{
    return { 
            setShowMenu:(item)=>dispatch(setShowMenu(item)),
            setShowModal:(item)=>dispatch(setShowModal(item)),
            setAuth:(item)=>dispatch(setAuth(item)),
            setModalStack:(item)=>dispatch(setModalStack(item))
          }
} 

const mapStateTopProps = state =>{
    return {...state,...state.sys,...state.firebase}
} 
export default connect(mapStateTopProps,dispatchToProps)(CreateContract)