import React from 'react'
import {setShowMenu} from '../actions'; 
import { connect } from 'react-redux'; 
import Header from '../components/header/Header'
 
const Search = (props) => {
  return (
    <div style={{width:'100%',height:'70vh',marginTop:'auto',marginBottom:'0px',display:'flex',flexDirection:'column',justifyContent:'flex-start'}}>
        <Header data="Find a service..." />
        {props?.searchText}
    </div>
  )
}
 
const dispatchToProps = dispatch =>{
    return { 
            setShowMenu:(item)=>dispatch(setShowMenu(item))
          }
} 

const mapStateTopProps = state =>{
    return {...state,...state.sys}
} 
export default connect(mapStateTopProps,dispatchToProps)(Search)