import { useEffect, useState } from "react"
import useWindowDimensions from "./useWindowDimensions"
function Theme() {
    const BREAK_POINT = 700
    const [isMobile,setIsMobile] = useState(true);
    const { width} = useWindowDimensions() 
    const logoStyle = {fontWeight:'900',fontSize:'32px'} 
    const WHITE = "rgba(255,255,255,1)" 
    const ButtonMain = "rgb(88, 48, 144)"
    const TESTEROpace = "#E2BAD0" 
    const SOURCE_SANS_PRO = 'Raleway, sans-serif'
    const dark_green_text = "rgba(19, 70, 19,1)"
    const fonts = {main:SOURCE_SANS_PRO}
    const styleHeaderModal={margin:'auto',fontSize:'28px',fontWeight:'900',marginLeft:'5%',width:'90%',marginTop:'5px',marginBottom:'15px'}
    const title = {fontFamily:fonts.main,fontSize:'22px',fontWeight:'900',textAlign:'center'}
    const text ={fontFamily:fonts.main,fontWeight:'500'}
    const colors = { lighttext:'rgba(0,0,0,0.8)',mainDark:'rgba(46, 46, 49, 0.95)',button:ButtonMain, main:'rgba(46, 46, 49, 0.5)',mainlight:'rgba(46, 46, 46, 0.005)',secondary:WHITE,dark_green_text,tertiary:'',greenlight:'rgba(0, 128, 24,0.7)'}
    const header ={fontSize:'36px',fontWeight:'900',color:colors.button,fontFamily:fonts.main }
    const button = { color:colors.secondary ,backgroundColor:colors.button,fontFamily:fonts.main, minWidth:'80px',minHeight:'30px' ,fontWeight:'800', borderRadius:'8px',cursor:'pointer',fontSize:'16px',alignItems:'center',margin:'auto',display:'flex',padding:'8px',transition:'all ease-in-out 250ms' }
    const toolbarStyle = {width:'100%',margin:'auto',marginTop:'0px',minHeight:'10vh',backgroundColor:'transparent',borderBottom:'solid thin rgba(0,0,0,0.2)',boxShadow:'0px -4px 10px rgba(0,0,0,0.1)'}
    const links = {margin:'auto',display:'flex',width:'100%',height:'10vh'}
    const link = {color:colors.button,fontFamily:fonts.main ,fontWeight:'200',cursor:'pointer',fontSize:'18px',alignItems:'center',margin:'auto',display:'flex',padding:'3px'}
    const baseTheme = {modalHeader:styleHeaderModal,toolbarStyle:toolbarStyle,header:header,text:text,title:title,fonts:fonts,button:button,colors:colors,links:links,link:link,logoStyle:logoStyle,shadow:'0px 0px 5px rgba(0,0,0,0.5)'}
    const mobileTheme = {...baseTheme,styleHeaderModal:{...styleHeaderModal,fontSize:'18px'},isPastBreak:true,header:{...baseTheme.header,fontSize:'24px'},title:{...baseTheme.title,fontSize:'16px'},button:{...baseTheme.button, fontSize:'14px',padding:'3px' }}
    const [theme,settheme] = useState({...baseTheme ,isPastBreak:true})
    useEffect(()=>{
        if(width <= BREAK_POINT){
            setIsMobile(true)
        }else{
            // if(isMobile === true) setIsMobile(false)
            setIsMobile(false)
        }
    },[width])

    useEffect(()=>{
        if(isMobile){ 
            settheme({...mobileTheme})
        } else {
            settheme({...baseTheme,isPastBreak:false})
        }

    },  [isMobile])

    useEffect(()=>{
        
    },[theme])
    
  return theme
}

export default Theme