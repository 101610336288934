import React, {  useEffect} from 'react'
import {setShowMenu,setDoc,setShowModal,setModalContent} from '../../../actions'; 
import { connect } from 'react-redux';   
import MainDiv from '../components/MainDiv';
import Selector from '../../../components/selector/Selector';
import BuisinessEditor from '../components/BuisinessEditor';
import BuisinessFinancialEditor from '../components/BuisinessFinancialEditor';


const BizSettings = (props) => { 
  
  useEffect(()=>{ 

  },[props?.auth.auth])
 

   //{link:'Payment Methods',screen: <Cards/>  } add later todo
  return (
      <MainDiv>
        <Selector key="bizsettings" title={props?.auth?.doc?.name+'\'s Settings'} items={[{link:'Buisiness Settings',screen:<BuisinessEditor/>},{link:'Financial Settings',screen: <BuisinessFinancialEditor/>  },]} /> 
      </MainDiv>
    
  )
}
 
const dispatchToProps = dispatch =>{
    return { 
            setShowMenu:(item)=>dispatch(setShowMenu(item)),
            setDoc:(item)=>dispatch(setDoc(item)),
            setShowModal:(item)=>dispatch(setShowModal(item)),
            setModalContent:(item)=>dispatch(setModalContent(item))
          }
} 

const mapStateTopProps = state =>{
    return {...state,...state.sys}
} 
export default connect(mapStateTopProps,dispatchToProps)(BizSettings)