import currency from 'currency.js';
import Types from '../utilities/Types';
const TOTAL_FEE = Types.TOTAL_FEE
export const getPaymentCount=(receipts)=>{
  let trueCount = 0
  receipts?.forEach(item=>{
    if(item.receiptId) {trueCount = trueCount + 1}
  })
  return trueCount
}
export const  milliToFormattedDate = (dateMilli) =>{
    const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  let date = new Date(dateMilli); // Date 2011-05-09T06:08:45.178Z
  let year = date.getFullYear();
  let day = ("0" + date.getDate()).slice(-2);  
  return `${monthNames[date.getMonth()]} ${day}, ${year}`
}

export const getTotalFees = (_total) =>{   
  if(_total){ 
     
    const totalConverted = currency(_total).divide(100)
    let feeCheck = currency(totalConverted).add(2).multiply(TOTAL_FEE).add(2)
    return feeCheck.format()
  }
  
}

export const getTotalFeesPaid = (_total,paymentNumber) =>{   
  if(_total){ 
    const totalConverted = currency(_total).divide(100)
    let feeCheck = currency(totalConverted).add(0.5*paymentNumber).multiply(TOTAL_FEE).add(0.5*paymentNumber)
    return feeCheck.format()
  }
  
}

export const getTotalPaid = (_total) => {  
  if(_total){ 
    const totalConverted = currency(_total).divide(100)
    let feeCheck = currency(totalConverted).add(2).multiply((1 + TOTAL_FEE))
    return feeCheck.format()
  }
}

export const getWeeklyPaymentTotals = (cardDoc)=>{
 if(cardDoc){
    const origTotal = cardDoc['total']
    const totalConverted = currency(origTotal).divide(100)
    let totalSingle =  currency(totalConverted).divide(4)
   
    let lastTotal = totalSingle
    let totalPaidAfter3 = currency(totalSingle).multiply(3)
    if(Number(currency(totalConverted).subtract(totalPaidAfter3).toString()) > Number(lastTotal.toString())){
      lastTotal = currency(totalConverted).subtract(totalPaidAfter3)
   
    } else if(Number(currency(totalConverted).subtract(totalPaidAfter3).toString()) < Number(lastTotal.toString())){
      lastTotal = currency(totalConverted).subtract(totalPaidAfter3)
 
    } else {
      //nothing
    }
    totalSingle = totalSingle.format()
    return [totalSingle+" ",totalSingle+" ",totalSingle+" ",lastTotal.format()]
  } 
  
}

export const logout = (props,nav) =>{
  props?.firebase.firebase.mySignOut();props?.setAuth(null);props?.setDoc(null);nav("/");props?.setContracts([]);
}