
import reducers from './reducers/index'  
import {
  BrowserRouter, 
} from "react-router-dom";  
import {createStore,applyMiddleware} from 'redux'
import {Provider} from 'react-redux' 

import thunk from 'redux-thunk' 
import Main from "./Main"; 
function App( ) {
const store = createStore(reducers,{},applyMiddleware(thunk))

return (  <Provider store={store}> 
              <BrowserRouter> 
                <Main/>
              </BrowserRouter>
            </Provider>
  );
}

export default App;
