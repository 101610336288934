import React, { useState ,useEffect} from 'react'
import {setShowMenu,setDoc,setShowModal,setModalContent} from '../../../actions'; 
import { connect } from 'react-redux';  
import Theme from '../../../components/theme/Theme';
import Types from '../../../utilities/Types'
import Button from '../../../components/button/Button';
import axios from 'axios'; 
import Text from '../../../components/text/Text'; 

const BuisinessFinancialEditor = (props) => {
  const theme = Theme() 
  const [loading,setLoading] = useState(false)
  const [data,setData] = useState(null)
 
  
  useEffect(()=>{
    // getCards()
  },[props?.auth.auth])
 
  
  const connectStripe = async () => {
    setLoading(true)
    const tok = await props?.auth.auth.getIdToken(); 
    axios.post(Types.baseUrl+"linkAccountStripe",{token:tok}).then(data=>{  
      if(data.data.status==='Success'){
        window.open(data.data.data,"_blank") 
        setData(data.data.data) 
        setLoading(false)
      } else {
        alert(JSON.stringify(data.data)) 
        setLoading(false)
      } 
    }).catch(e=>{
      
      setLoading(false)
    })

  }

   
  return (
    <> 
        {/* <Title style={{marginTop:'5px',marginLeft:'2.5%',marginBottom:'0px'}} data="Buisiness Financials" />         */}
        {props?.auth?.doc?.sid || data? null :<Button isDisabled={loading} hover={{ }} click={connectStripe} style={{paddingLeft:'5px',paddingRight:'5px',border:'1px solid black',color:theme?.colors?.main,fontWeight:'900',maxWidth:'80%',marginTop:'20px',marginBottom:'10px'}} name={'Click Here To Begin Collecting Payments'} /> }
        {!data ? null :<Button isDisabled={loading} hover={{ }} click={()=>{window.open(data,"_blank") }} style={{paddingLeft:'5px',paddingRight:'5px',border:'1px solid black',color:theme?.colors?.main,fontWeight:'900',maxWidth:'80%',marginTop:'20px',marginBottom:'10px'}} name={'Click here if no window opens.'} /> }
        {props?.auth?.doc?.sid? <Text style={{margin:'auto',marginBottom:'20px',textAlign:'center',marginTop:'10px'}} data="" >Stripe Connected <br/> Ready to Accept Payments.</Text> :null }
    </>
    
  )
}
 
const dispatchToProps = dispatch =>{
    return { 
            setShowMenu:(item)=>dispatch(setShowMenu(item)),
            setDoc:(item)=>dispatch(setDoc(item)),
            setShowModal:(item)=>dispatch(setShowModal(item)),
            setModalContent:(item)=>dispatch(setModalContent(item))
          }
} 

const mapStateTopProps = state =>{
    return {...state,...state.sys}
} 
export default connect(mapStateTopProps,dispatchToProps)(BuisinessFinancialEditor)