import React ,{useState,useEffect} from 'react'
import Theme from '../theme/Theme'
import { Circles } from 'react-loader-spinner'

export const Button = (props) => {
  const stylebase = Theme()
  const [style,setStyle] = useState({...stylebase.button,...props?.style})
 
  useEffect(()=>{

  },[style,stylebase,props?.isDisabled ])
  
  return (
    <div   
    onMouseLeave={()=>{setStyle({...style,backgroundColor:stylebase.button.backgroundColor,boxShadow:'none',color:stylebase.button?.color,...props?.style  })}}  
    onClick={(e)=>{
      e.stopPropagation();
      if(props?.isDisabled){ 
        return
      }
      if(props?.click){props?.click();}
      setStyle({...style  ,...props?.hover,color:props?.style?.color ? props.style?.color :stylebase?.button?.color})
    }}  
    onMouseEnter={()=>{setStyle({...style,...props,boxShadow:'0px 0px 15px '+stylebase.colors.main  ,...props?.hover})}} 
    style={{...style }}> 
    <div style={{display:'flex',width:'auto',margin:'auto'}}>
    {props?.isDisabled?<Circles
      height="20"
      width="20"
      color={stylebase?.colors?.button}
      ariaLabel="circles-loading"
      wrapperStyle={{margin:'auto',backgroundColor:'white',borderRadius:'25px',padding:'5px'}}
      wrapperClass=""
      visible={true}
    />:props.name}{props.children} </div></div>
  )
}
export default Button
