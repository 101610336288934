import React from 'react'
import {setShowMenu} from '../actions'; 
import Theme from '../components/theme/Theme';
import { connect } from 'react-redux'; 
import Header from '../components/header/Header';
import Title from '../components/title/Title'; 
import Button from '../components/button/Button'; 
import { setShowModal,setModalContent } from '../actions'; 
import Support from '../modals/Support';
import BottomBar from '../components/nav/BottomBar'; 
const Help = (props) => {
  const theme = Theme();

  const showModal = (isProvider)=>{props?.setModalContent(<Support/>);props?.setShowModal(true)}
  return (
    <div style={{width:'100%' ,minHeight:'93vh' ,height:'auto' ,display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
        <img />
        <div style={{width:theme?.isPastBreak ? '90%' :'80%',padding:'15px' ,display:'flex',flexWrap:'wrap',minHeight:theme?.isPastBreak?'auto':'auto',margin:'auto',flexDirection:'column',marginTop:'5vw',marginBottom:'2vw',borderRadius:'12px'}}>
        <Header data="Help"  style={{margin:'auto',width:'auto'   ,padding:'8px',fontWeight:'900',marginBottom:'0vw',marginTop:'2vw',fontSize:theme?.isPastBreak?'34px':'60px',textAlign:'center' }} />
         <Title style={{margin:'auto',textAlign:'center',maxWidth:'400px',color:theme?.colors?.button,padding:'8px' ,fontWeight:'200',marginTop:'2vw'}} data="">Lets get some help.<br/>
              <Button click={(e)=>{
                showModal()
              }}  
                 style={{ padding:'10px',color:theme?.colors?.secondary,marginTop:'10px',boxShadow:'0px 0px 3px '+theme.colors.main,fontWeight:'900'}} name="Submit a Support Ticket" />
              </Title>
        </div>
{/*  
         <Header data="FAQ"  style={{margin:'auto',width:'auto'   ,padding:'8px',fontWeight:'900',marginBottom:'0vw',marginTop:'8vw',fontSize:theme?.isPastBreak?'34px':'60px',textAlign:'center' }} />

<div style={{width:theme?.isPastBreak ? '80%' :'60%',padding:'15px',boxShadow:'0px 0px 4px rgba(0,0,0,0.2)',backgroundColor:'whitesmoke',display:'flex',flexWrap:'wrap',minHeight:theme?.isPastBreak?'auto':'auto',margin:'auto',flexDirection:'column',marginTop:'1vw',marginBottom:'6vw',borderRadius:'12px'}}>
  <DropDownBox  minHeight={500} maxHeight='auto' name={'Taciti   ad litora torquent'}>
      
  </DropDownBox> 
  <DropDownBox  minHeight={500} maxHeight='auto' name={'   ad   torquent'}> 
  </DropDownBox> 
  <DropDownBox  minHeight={500} maxHeight='auto' name={'Taciti sociosqu ad litora  '}> 
  </DropDownBox> 
  <DropDownBox  minHeight={500} maxHeight='auto' name={'   ad litora torquent'}> 
  </DropDownBox> 
  <DropDownBox  minHeight={500} maxHeight='auto' name={'  sociosqu ad litora torquent'}> 
  </DropDownBox> 
</div>  */}
        <BottomBar/>
    </div>
  )
}
 
const dispatchToProps = dispatch =>{
    return { 
            setShowMenu:(item)=>dispatch(setShowMenu(item)),
            setModalContent:(item)=>dispatch(setModalContent(item)),
            setShowModal:(item)=>dispatch(setShowModal(item))
          }
} 

const mapStateTopProps = state =>{
    return {...state,...state.sys}
} 
export default connect(mapStateTopProps,dispatchToProps)(Help)