import React, { useState ,useEffect} from 'react'
import {setShowMenu,setDoc,setShowModal,setModalContent} from '../../../actions'; 
import { connect } from 'react-redux'; 
import ContractReceiptCard from '../../../components/contractcard/ContractReceiptCard';  
import Theme from '../../../components/theme/Theme'; 
import Button from '../../../components/button/Button'; 
import Text from '../../../components/text/Text';
import Header from '../../../components/header/Header';
import { getPaymentCount } from '../../../utilities';
import Cards from '../../../components/card/Cards'; 
import Types from '../../../utilities/Types';
import axios from 'axios';
import { GiPayMoney } from 'react-icons/gi';
import { AiOutlineEdit } from 'react-icons/ai';
import { ArrowCircleLeftSharp } from '@mui/icons-material';
const Contract = (props) => {
  const theme = Theme() 
  const [loading,setLoading] = useState(false)
  const [card,selectCard] = useState('')
  const [updatePayment,setUpdatePayment] = useState(false)
  const [doc,setData] = useState({}) 
 
  useEffect(()=>{ 
    setData(props?.data)
  },[props?.data, props?.loading]) 

  const [sending,setSending] = useState(false) 
  

  const click = async ()=>{
    if(!window.confirm("Are you sure you wish to charge your card now?"))  return 
    setSending(true) 
    const tok = await props?.auth.auth.getIdToken();   
    await axios.post((Types.baseUrl+"chargeCont"),{contractId:doc?.id, token:tok}).then(async item=>{  
      window.alert(item?.data.message) 
      setSending(false)
      props?.setShowModal(false)
      props?.setModalContent(null)
    }).catch(e=>{
      window.alert(JSON.stringify(e.message))
 
      props?.setShowModal(false)
      props?.setModalContent(null)
      setSending(false)
    })
  }

  const updateCard =  async () =>{ 
    setLoading(true)
    const tok = await props?.auth.auth.getIdToken(); 
    axios.post(Types.baseUrl+'updateContractPM',{cpid:card,token:tok,contractKey:doc?.id}).then(res=>{ 
            alert(res.data.message) 
            //update ui here
            if(res.data.status === 'Success'){  
               console.log("Contract PM Updated")   
              } 
              props.resetUserData();
              setUpdatePayment(false)
              selectCard('')
              setLoading(false)  
          }).catch(e=>{
            alert(e.message)
            setLoading(false)
          }) 
  }
  
  const styletext = {margin:'auto',marginTop:'12px',marginLeft:'5%',color:theme?.colors?.lighttext}
  const styleButonOptions =  {marginTop:'auto',display:'flex',flexShrink:'0' ,fontSize:'14px',minWidth:'auto',marginBottom:'auto',marginRight:'10px',padding:'4px',textAlign:'center' ,marginLeft:'0px'  ,fontWeight:'900',borderBottom:'2px solid '+theme.colors.button,backgroundColor: 'white',color:theme?.colors?.button,borderRadius:'2px',height:'auto',minHeight:'auto',transition:'all ease-in-out 250ms'}
  
  return (
    <div style={{transition:'all 500ms ease-in-out',width:'100%',margin:'auto' ,paddingTop:'0px' ,marginTop:'10px',height:'auto',borderRadius:'18px',borderTopLeftRadius:'0px',borderTopRightRadius:'0px',marginBottom:'0px',paddingBottom:'20px' ,backgroundColor:'white',display:'flex',boxShadow:'none',flexDirection:'column'}}>
          { !updatePayment?
            <div> 
            

            {4 - getPaymentCount(doc['receipts']) === 0 ? <Text style={{...styletext,color:theme?.colors.button}} ><b>Contract has been paid off. {<br/>} Total Paid ${  ((parseFloat(doc['paid'])+ parseFloat(doc['feesPaid']))/100).toFixed(2)}</b><br/></Text>:''}
           <div style={{display:'flex',width:'auto',margin:'auto' ,marginRight:'5%',height:'40px',marginTop:'0px' }}>
           <Header  style={{...theme.modalHeader,marginRight:'0px',width:'auto'}} data="Contract Info" />   
                   { props?.auth?.doc?.type === "provider" || 4 - getPaymentCount(doc['receipts']) === 0 ?null:  
                   <div style={{margin:'auto',marginRight:'0px',marginLeft:'8px',width:'auto' }}>
                    <AiOutlineEdit icon={''} onClick={()=>setUpdatePayment(true)}   style={{ height:'24px',cursor:'pointer',width:'24px',marginTop:'8px' ,color:theme?.colors?.button   }}  name={"Edit Payment Method"} />
                    </div>}
                   {props?.auth?.doc?.type === "provider" || 4 - getPaymentCount(doc['receipts']) === 0 ?null: 
                    <div style={{margin:'auto',marginLeft:'8px',width:'auto' }}>
                    <GiPayMoney isDisabled={sending}  onClick={click}  
                    style={{ height:'24px',width:'24px',cursor:'pointer',marginTop:'8px' ,color:theme?.colors?.button   }} name={props?.isUpcoming?"Pay Now":"Pay Early"}/>
                    </div>
                    }
            </div>
            <div style={{backgroundColor:'rgba(0,0,0,0.02)',border:'1px whitesmoke solid',borderRadius:'12px',borderTopLeftRadius:"12px",borderTopRightRadius:'12px',width:'90%',margin:'auto',marginLeft:'5%',marginTop:'10px',marginBottom:'5px',paddingBottom:'5px'}}>
              <div style={{display:'flex',marginTop:'4px'}}>
                <Text style={{...styletext,width:'50%'}} ><b> Buisiness <br/></b>{ doc?.bizName}<br/></Text>
                <Text style={{...styletext,width:'50%'}} ><b> Customer <br/></b>{ doc?.CustomerName}<br/></Text>
              </div>
              <div style={{display:'flex'}}>
                <Text style={{...styletext,width:'50%'}} ><b>Total</b><br/> ${   (parseFloat(doc['total'])/100).toFixed(2)} <br/></Text>
                <Text style={{...styletext,width:'50%'}} ><b>Paid</b><br/> ${   (parseFloat(doc['paid'])/100).toFixed(2)} <br/></Text>
              </div>
              <div style={{display:'flex'}}>
                <Text style={{...styletext,width:'50%'}} ><b>Fees</b><br/> ${(parseFloat(doc['totalfees'])/100).toFixed(2)} <br/></Text>
                <Text style={{...styletext,width:'50%'}} ><b>Fees Paid</b><br/> ${  (parseFloat(doc['feesPaid'])/100).toFixed(2)} <br/></Text>
              </div>
              <div style={{display:'flex'}}>
                <Text style={{...styletext,width:'50%'}} ><b>Total With Fees</b><br/> ${  ((parseFloat(doc['totalfees'])+ parseFloat(doc['total']))/100).toFixed(2)} <br/></Text>
                
              </div>
              <Text style={{...styletext,marginBottom:'4px'}} ><b>Description </b> <br/>{doc['description']}</Text>
            </div>
            
            <Text style={{...styletext}} ><b>Installment Info </b> 4 bi-weekly payments  <br/>  </Text>
     
            <div style={{display:'flex',marginBottom:'5px'}}>
              <Text style={{...styletext}} ><b>Payments Made </b>{   getPaymentCount(doc['receipts'])} <br/></Text>
              <Text style={{...styletext}} ><b>Remaining </b> {   4 - getPaymentCount(doc['receipts'])}<br/></Text>
            </div>

           
            <div style={{width:'100%',margin:'auto',marginTop:'0px',borderRadius:'12px',minHeight:'0px',display:'flex',flexDirection:'column',flexWrap:'wrap' }}>
             {doc?.receipts?.map((item,index)=>{
              return <ContractReceiptCard index={index} item={item} />
             })}
            </div>
            
            </div>  :
            <div>
            <Header  style={theme.modalHeader} data="Update Payment Method" /> 
           
             {/* <Text style={{...styletext}} ><b>Current Card #: ************{  doc?.payment?.last4} </b><br/></Text> */}
            
             <Text style={{...styletext,marginBottom:'0px',marginTop:'25px'}} ><b>{  'Select your new payment method to continue.'} </b><br/></Text>
             <div style={{display:'flex',width:'auto',margin:'auto',marginLeft:'5%',height:'40px',marginBottom:'10px',marginTop:'4px'}}>
           
            {loading?null:<div><ArrowCircleLeftSharp   onClick={()=>{setUpdatePayment(false)}}  style={{ height:'24px',width:'24px',cursor:'pointer',marginTop:'8px' ,color:theme?.colors?.button   }}  /></div>}
            {card !== ''?<Button isDisabled={loading} click={()=>{console.log(card);updateCard()}} hover={{ }}  style={{...styleButonOptions,marginLeft:'8px' }} name={'Update'} />:null}
            </div>
            <Cards style={{paddingTop:'5px',backgroundColor:'rgba(0,0,0,0.02)',border:'1px whitesmoke solid',borderRadius:'12px',borderTopLeftRadius:"12px",borderTopRightRadius:'12px',width:'90%',paddingBottom:'5px'}}  selectCard={selectCard} selectedId={card} isSelect={true}/>
            <div style={{display:'flex',width:'auto',margin:'auto',marginLeft:'5%',height:'40px',marginTop:'0px'}}>
           
           
             </div>
            </div>
            }
    </div> 
  )
}
 
const dispatchToProps = dispatch =>{
    return { 
            setShowMenu:(item)=>dispatch(setShowMenu(item)),
            setDoc:(item)=>dispatch(setDoc(item)),
            setShowModal:(item)=>dispatch(setShowModal(item)),
            setModalContent:(item)=>dispatch(setModalContent(item))
          }
} 

const mapStateTopProps = state =>{
    return {...state,...state.sys}
} 
export default connect(mapStateTopProps,dispatchToProps)(Contract)