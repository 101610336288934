import React from 'react'
import Theme from '../theme/Theme'
import { useNavigate } from 'react-router-dom'
export const Link = (props) => {
  const nav = useNavigate() 
  const style = Theme().link
  return (
    <div  onClick={(e)=>{
      e.preventDefault();
      if(props.click){
        props.click(e)
      }else{
        nav("/"+props.link+"")
        if(props?.subtleclick){
          props?.subtleclick(e)
        } 
      }      
    }} style={{...style,...props?.style}}>{props.icon} {props.link}</div>
  )
}
