import React, { useEffect,useState } from 'react'
import Theme from '../theme/Theme' 
import { Link } from './Link'
import NameLogo from './NameLogo'
import {routes,otherroutes,} from './routes' 
import Menu from '@mui/icons-material/Menu'; 
import MenuNav from './MenuNav' 
import {setShowMenu,setShowModal,setModalContent,setAuth,setDoc, setSearchText} from '../../actions'; 
import { connect } from 'react-redux'; 
import {AiOutlineClose} from 'react-icons/ai'
import { useNavigate } from "react-router-dom";
import Login from '../../modals/Login';
import SignUp from '../../modals/SignUp';
import InputComponent from '../input/InputComponent'
import Search from '../../modals/Search' 
import Button from '../button/Button'

const Toolbar = (props) => { 
    const nav = useNavigate()
    const [show,setShow] = useState(false);
    const theme = Theme() 
    useEffect(()=>{ 

    },[ theme.isPastBreak])
    
    const getTextInput = (smaller) =>{
        const style ={width: smaller?'100px' :'99%',
        maxWidth: smaller?'100px' :'100vw',
        margin:'auto', 
        marginTop:'8px',
        height:'30px',
        marginRight:smaller? '-1%':'auto',  
        backgroundColor:'rgba(255,255,255,0.8)'}
        if (smaller || window.location.pathname === "/UserHome"  ) return null;
        return <InputComponent label="Search" hideLabel={true} style={style} 
                onClick={(e)=>{
                    if(e.target.value !== ''){ 
                        if(props?.showModal === false){
                            props?.setShowModal(true);
                        } 
                        props?.setModalContent(<Search/>);
                    }   
                }}
                onChange={(e)=>{
                    if (e.target.value === ""){
                        props?.setModalContent(null);
                        if(props?.showModal === true){
                            props?.setShowModal(false);
                        }
                    }else{
                        props?.setSearchText(e.target.value)
                      
                            props?.setModalContent(<Search/>);
                       
                        if(props?.showModal === false){
                            props?.setShowModal(true);
                        } 
                    }
                }} 
                  /> 
    }

    const loginFunc = ()=>{props?.setModalContent(<Login/>);props?.setShowModal(true)}
    const signupFunc = ()=>{props?.setModalContent(<SignUp/>);props?.setShowModal(true)}
    const logoutFunc = ()=>{props?.firebase.firebase.mySignOut();props?.setAuth(null);props?.setDoc(null);nav("/")}
    
    const getMenu = (style)=>{ 
        return show?   <AiOutlineClose onClick={()=>{
            setShow(!show)
            // props?.setModalContent(<MenuNav />);props?.setShowModal(true)
        
        }} style={{...style,cursor:'pointer',color:theme.colors.button}} /> :  <Menu onClick={()=>{
            setShow(!show)
            // props?.setModalContent(<MenuNav />);props?.setShowModal(true)
        
        }} style={{...style,cursor:'pointer',color:theme.colors.button}} />
    }
    const themeBox = {width:"100%",padding:'0px',minHeight:'70px' ,borderRadius:'0px',display:'flex',flexFlow:'column',margin:'auto',height:'auto',backgroundColor:'transparent',flexWrap:'wrap',overflow:'hidden',justifyContent:'center'}
    const styleMarginsLinks = {marginLeft:'12px',color:theme?.colors?.button,marginRight:'5px',padding:'3px',fontSize:'15px',fontWeight:'900',height:'auto',minHeight:'auto',width:'auto' }
    const getUI = ()=>{
        return  <div style={{...themeBox ,backgroundColor:theme.colors.secondary }}>
                        <div style={{width:'98%' ,display:'flex',flexFlow:'row',alignItems:'center',margin:'auto',marginTop:'auto',marginBottom:'auto',height:'auto',borderRadius:'12px'}}>
                            {/* {getMenu({width:'40px',height:'40px',margin:'auto',marginRight:'0%',marginLeft:'2%'})} */}
                            <NameLogo style={{margin:'auto' ,fontWeight:'900' ,marginRight:'auto',fontFamily:theme.fonts.main,letterSpacing:'1px',color:theme.colors.button,fontSize:'30px',marginLeft:'16px', }} />
                           
                            {/* {getTextInput()} */}
                            
                             {
                                Object.keys(routes).map((key,index)=>{
                                    return <Link style={{...styleMarginsLinks,color:theme.colors.button,borderRadius:'10px',padding:'2px',marginBottom:'auto'}} key={key} id={key} link={routes[key]} />
                                })
                            } 
                            {
                                Object.keys(otherroutes).map((key,index)=>{
                                    if(otherroutes[key]==="Login"||otherroutes[key]==="Signup") return null
                                    return <Link style={{...styleMarginsLinks,color:theme.colors.button,borderRadius:'10px',padding:'2px',marginBottom:'auto'}} key={key} id={key} link={otherroutes[key]} />
                                })
                            }  
                            {props?.auth.auth?<Link click={()=>{logoutFunc()}} link="Logout" style={{...styleMarginsLinks   ,borderRadius:'4px',marginRight:'10px'}} /> :<Link click={()=>{loginFunc()}} link="Login" style={{...styleMarginsLinks ,borderRadius:'4px'}} />}
                            {props?.auth.auth && window.location.pathname !== "/UserHome"?<Button click={()=>{nav("/UserHome")}} name="Account" style={{...styleMarginsLinks,color:theme.button.color,borderRadius:'8px',minHeight:'2vh'  ,padding:'10px',marginRight:'12px'}} />:props?.auth.auth ?null:<Button click={()=>{signupFunc()}} name="Signup" style={{...styleMarginsLinks,color:theme.button.color,borderRadius:'8px',minHeight:'2vh'  ,padding:'10px',marginRight:'12px'}} />}
                         
                        </div>
                </div>
    }
    const getUIMobile = ()=>{
        return  <div style={{...themeBox ,backgroundColor:theme.colors.secondary}}>
        <div style={{width:'98%',display:'flex',flexFlow:'row',margin:'auto',marginTop:'auto',marginBottom:'auto',height:'auto'  }}>
       
           
            {/* <Link style={{...styleMarginsLinks}} link={"How it Works"} />
            <Link style={{...styleMarginsLinks}} link={"For Servicers"} />
            <Link style={{...styleMarginsLinks,marginRight:'auto'}} link={"Get the App"} /> */}
            
            <NameLogo style={{ marginRight:'auto',fontSize:'30px',marginLeft:'16px',color:theme.colors.button,letterSpacing:'1px',fontFamily:theme.fonts.main  }} />
            {getMenu({width:'40px',height:'40px',margin:'auto',marginRight:'16px',marginLeft:'auto'})}
        </div>
        {/* <div style={{width:'100%',display:'flex',flexFlow:'row',margin:'auto',marginBottom:'auto',marginTop:'0%',height:'auto',backgroundColor:'transparent' }}>
             {getTextInput(true)} 
        </div> */}
       
    </div>
    }
  return (
    // (254, 200, 216)
    window.location.pathname === "/UserHome" && theme.isPastBreak === false? null:<div style={{width:'100%',paddingBottom:'0px',backgroundColor:theme.colors.secondary,borderBottom:'solid thin rgba(0,0,0,0.05)',display:'block' ,flexFlow:'column',margin:'auto',overflow:'hidden'  ,zIndex:'50',minHeight:show && theme.isPastBreak? "70px":'70px',maxHeight:show && theme.isPastBreak? "50vh":'70px',transition:'all 500ms ease-in-out'}} >
        {theme.isPastBreak? getUIMobile():getUI()} 
        <MenuNav close={()=>setShow(false)}/> 
    </div>
  )
}


const dispatchToProps = dispatch =>{
    return { 
            setShowMenu:(item)=>dispatch(setShowMenu(item)),
            setShowModal:(item)=>dispatch(setShowModal(item)),
            setModalContent:(item)=>dispatch(setModalContent(item)),
            setAuth:(item)=>dispatch(setAuth(item)),
            setSearchText:(item)=>dispatch(setSearchText(item)),
            setDoc:(item)=>dispatch(setDoc(item))
          }
  } 
  
  const mapStateTopProps = state =>{
    return {...state,...state.sys}
  } 
  export default connect(mapStateTopProps,dispatchToProps)(Toolbar)