import React from 'react'
import { Link } from './Link'
import NameLogo from './NameLogo'
import Theme from '../theme/Theme'

const BottomBar = () => {
  const theme = Theme()
  return (
    <div style={{width:'100%',backgroundColor:theme?.colors.button  ,minHeight:'10vh',margin:'auto',marginBottom:'0px',display:'flex',marginTop:'auto',borderRadius:'0px'}}>
         
          
          <div style={{width:'90%',minHeight:'0px',margin:'auto',marginBottom:'auto',display:'flex',justifyContent:'center',marginTop:'auto',borderRadius:'0px'}}>
              <Link style={{fontWeight:'400',margin:'auto',color:theme?.colors?.secondary,fontSize:'16px'}} link="Home"/>
              <Link style={{fontWeight:'400',color:theme?.colors?.secondary,fontSize:'16px'}} link="Pricing"/>
              <Link style={{fontWeight:'400',color:theme?.colors?.secondary,fontSize:'16px'}} link="Help"/> 
          </div>
    </div>  
  )
}

export default BottomBar