import React,{useEffect,useState} from 'react'
import {setShowMenu,setShowModal,setModalContent} from '../../../actions'; 
import { connect } from 'react-redux';  
import Text from '../../../components/text/Text';
import Button from '../../../components/button/Button'; 
import ConfirmContract from '../modals/ConfirmContract';
import Theme from '../../../components/theme/Theme';
import { Circles } from 'react-loader-spinner';
import Title from '../../../components/title/Title';
const Notifications = (props) => {
    const [notes,setNotes] = useState([])
    const [firstLoad,setFirstLoad] = useState(false)
    const theme = Theme()
    const getNotes =  async () => {
      setNotes(props?.auth?.notifications)
      
      }

    useEffect(() => {
      setNotes(props?.auth?.notifications)
    },[props?.auth?.notifications])
    useEffect(()=>{

    },[notes,theme?.isPastBreak])
    const deletedNote = (item) => {
      props?.firebase?.firebase?.myDeleteDoc(item?.id,"users/"+props?.auth?.doc?.id+"/notifications")
    }
    const  hovernotibutton = {borderBottom:'0px solid white',color:theme?.colors?.button,backgroundColor:theme?.colors.secondary,boxShadow:'hidden'}
    const stylenotibutton = { padding:'3px',minWidth:'45px',width:'auto',height:'auto',borderRadius:'6px',minHeight:'auto',paddingLeft:'5px',paddingRight:'5px',margin:'auto',marginRight:'0px',borderBottom:'2px solid '+theme?.colors?.main  ,fontSize:'12px'  }
    const notStruc = (children,click,key)=>{
       return  <div key={key} onClick={(e)=>{if(click) click(e)}} style={{display:'flex',margin:'auto',width:'100%',marginLeft:'auto',marginTop:'10px',padding:'5px',paddingTop:'2px', backgroundColor:'rgba(0,0,0,0.03)',borderRadius:'8px',maxWidth:'90%'}}>
                    {children}
                </div>
    }
    const getNoti = (item)=>{
        switch(item.type){
            case 'NEW_CONTRACT': 
                return  notStruc([<Text style={{marginRight:'0px',fontSize:theme?.isPastBreak?'12px':'14px'}}><b> New Payment Request </b><br/>{item?.contract?.bizName}</Text>,
                                <Text style={{marginLeft:'5px',marginRight:'auto',fontSize:theme?.isPastBreak?'12px':'14px'}}><br/>${(item?.contract?.total/100).toFixed(2)}</Text>,
                                <Button
                                  click={()=>{
                                    props?.setModalContent(<ConfirmContract resetUserData={props?.resetUserData} getNotes={getNotes} data={{...item,...item?.contract}}  />)
                                    props?.setShowModal(true)
                                }}
                                  hover={hovernotibutton} 
                                style={stylenotibutton} name="confirm"/>
                                ],
                            (e)=>{
                                props?.setModalContent(<ConfirmContract resetUserData={props?.resetUserData} getNotes={getNotes} data={{...item,...item?.contract}}  />)
                                props?.setShowModal(true)
                            },item.id)
            case 'CONTRACT_CONFIRMED':
                  return notStruc([<Text style={{marginRight:'0px',fontSize:theme?.isPastBreak?'12px':'14px'}}><b> Contract with </b><br/>{item?.customerName ?? item?.bizName} confirmed.</Text>, <Button click={()=>{deletedNote(item)}}  hover={hovernotibutton}  style={stylenotibutton}>clear</Button>],deletedNote(item))
            default:
                return 
        }
    }
  return (
   firstLoad? <Circles
                  height="40"
                  width="40"
                  color={theme?.colors?.main}
                  ariaLabel="circles-loading"
                  wrapperStyle={{margin:'auto',backgroundColor:'white',borderRadius:'25px',padding:'5px',marginTop:'20px'}}
                  wrapperClass=""
                  visible={true}
                /> : <div style={{width:'100%',height:'auto',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                <Title style={{ marginBottom:'5px'}} data="Notifications" />
         {firstLoad?null:notes.length === 0? <Text style={{margin:'auto'}} data="No Notifications"/> :null}
         {notes.map((item)=>{
            return getNoti(item)
         })}
    </div>

  )
}
 
const dispatchToProps = dispatch =>{
    return { 
            setShowMenu:(item)=>dispatch(setShowMenu(item)),
            setShowModal:(item)=>dispatch(setShowModal(item)),
            setModalContent:(item)=>dispatch(setModalContent(item)),
          }
} 

const mapStateTopProps = state =>{
    return {...state,...state.sys}
} 
export default connect(mapStateTopProps,dispatchToProps)(Notifications)