import React, {useEffect} from 'react'
import {setShowMenu} from '../../../actions'; 
import { connect } from 'react-redux';  
import History from '../components/History';
import ContractRequests from '../components/ContractRequests';
import UpcomingPayments from '../components/UpcomingPayments';
import Selector from '../../../components/selector/Selector';
import MainDiv from '../components/MainDiv';
const Purchases = (props) => {
  useEffect(()=>{

  },[props?.auth?.contracts,props?.auth?.completedcontracts])
  return (
   <MainDiv>
       <Selector key="purchases" title="Purchases" items={[{link:'Active',screen:<History title="Active Contracts" data={props?.auth?.contracts}/> }, {link:'Upcoming Payments',screen:<UpcomingPayments  data={props?.auth?.contracts}/>}, {link:'Requests',screen:<ContractRequests data={props?.auth?.contracts} />}, {link:'History',screen:<History title="Payment History"  data={props?.auth?.completedcontracts}/>},]}/>
    </MainDiv>
  )
}
 
const dispatchToProps = dispatch =>{
    return { 
            setShowMenu:(item)=>dispatch(setShowMenu(item))
          }
} 

const mapStateTopProps = state =>{
    return {...state,...state.sys}
} 
export default connect(mapStateTopProps,dispatchToProps)(Purchases)