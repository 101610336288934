 

 export const SET_DEVICE_TYPE = "set_device_type"
 export const setDeviceType = (data = "") => async (dispatch,getState) => { 
    return dispatch({
        type:SET_DEVICE_TYPE,
        payload:data
    }) 
}

 export const SET_SAVING = 'set_saving' 
 export const setSaving = (saving = false) => async (dispatch,getState) => { 
        return dispatch({
            type:SET_SAVING,
            payload:saving
        }) 
 }
 export const SET_MODAL_CONTENT = 'set_modal_content' 
 export const setModalContent = (content) => async (dispatch,getState) => {  
        return dispatch({
            type:SET_MODAL_CONTENT,
            payload:content
        }) 
 } 
 export const SET_MODAL_STACK = 'set_modal_stack' 
 export const setModalStack = (content) => async (dispatch,getState) => { 
        return dispatch({
            type:SET_MODAL_STACK,
            payload:content
        }) 
 }
 export const SET_SHOW_MODAL = 'set_show_modal' 
 export const setShowModal = (show) => async (dispatch,getState) => {  
        return dispatch({
            type:SET_SHOW_MODAL,
            payload:show
        }) 
 }
   
 export const SET_SCROLL = 'set_scroll' 
 export const setScroll = (item) => async (dispatch,getState) => {  
        return dispatch({
            type:SET_SCROLL,
            payload:item
        }) 
 }
 export const SET_BOTTOM = 'set_bottom' 
 export const setBottom = (item) => async (dispatch,getState) => {  
        return dispatch({
            type:SET_BOTTOM,
            payload:item
        }) 
 }
 export const SET_SHOW_MENU = 'set_show_menu' 
 export const setShowMenu = (item) => async (dispatch,getState) => {  
        return dispatch({
            type:SET_SHOW_MENU,
            payload:item
        }) 
 }
 export const SET_AUTH = 'set_auth' 
 export const setAuth = (item) => async (dispatch,getState) => {  
        return dispatch({
            type:SET_AUTH,
            payload:item
        }) 
 }
 
 export const SET_SEARCH_TEXT = 'set_search_text' 
 export const setSearchText = (item) => async (dispatch,getState) => {  
        return dispatch({
            type:SET_SEARCH_TEXT,
            payload:item
        }) 
 }
 
 export const SET_DOC = 'set_doc' 
 export const setDoc = (item) => async (dispatch,getState) => {  
        return dispatch({
            type:SET_DOC,
            payload:item
        }) 
 }

 export const SET_CARDS = 'set_cards' 
 export const setCards = (item) => async (dispatch,getState) => {  
        return dispatch({
            type:SET_CARDS,
            payload:item
        }) 
 }

 export const SET_CONTRACTS = 'set_contracts' 
 export const setContracts = (item) => async (dispatch,getState) => {  
        return dispatch({
            type:SET_CONTRACTS,
            payload:item
        }) 
 }
 
 export const SET_COMPLETED_CONTRACTS = 'set_completed_contracts' 
 export const setCompletedContracts = (item) => async (dispatch,getState) => {  
        return dispatch({
            type:SET_COMPLETED_CONTRACTS,
            payload:item
        }) 
 }

 export const SET_NOTIFICATIONS = 'set_notifications' 
 export const setNotifications = (item) => async (dispatch,getState) => {  
        return dispatch({
            type:SET_NOTIFICATIONS,
            payload:item
        }) 
 }
 export const SET_PENDING_CONTRACTS = 'set_pending_contracts' 
 export const setPendingContracts = (item) => async (dispatch,getState) => {  
        return dispatch({
            type:SET_PENDING_CONTRACTS,
            payload:item
        }) 
 }

 export const SET_SITE_NAV = 'set_site_nav' 
 export const setSiteNav = (item) => async (dispatch,getState) => {  
        return dispatch({
            type:SET_SITE_NAV,
            payload:item
        }) 
 }

 export const SET_USER_DATA = 'set_user_data' 
 export const setUserData = (item) => async (dispatch,getState) => {  
        return dispatch({
            type:SET_USER_DATA,
            payload:item
        }) 
 }
 export const SET_USER_NAV = 'set_user_nav' 
 export const setUserNav = (item) => async (dispatch,getState) => {  
        return dispatch({
            type:SET_USER_NAV,
            payload:item
        }) 
 }