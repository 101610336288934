import React ,{useEffect,useState} from 'react'
import {setShowMenu} from '../actions'; 
import { connect } from 'react-redux';  
import Text from '../components/text/Text'
import {setShowModal,setModalStack} from "../actions/index"
import Button from '../components/button/Button';
import Theme from '../components/theme/Theme';
import { setAuth } from '../actions';
import InputComponent from '../components/input/InputComponent';
import Types from '../utilities/Types';
import axios from 'axios'
import Header from '../components/header/Header';
const Support = (props) => {
const theme = Theme() 
const baseObj = {email:'',name:'',message:''}
const [doc,setDoc] = useState({...baseObj})
const [sending,setSending] = useState(false)
const getTextInput = (type) =>{
  const style ={width:"90%", 
  maxWidth:'90%',
  margin:'auto',  
  marginTop:'5px', 
  backgroundColor:'rgba(255,255,255,0.7)'}
  if(type === "message"){
    return  <InputComponent  style={style} multiline={true} rows={4}  onChange={(e)=>{setDoc({...doc,[type]:e.target.value})}} /> 
  }else {
    return  <InputComponent  style={style}   onChange={(e)=>{setDoc({...doc,[type]:e.target.value})}} /> 
  }
        
} 

useEffect(()=>{

},[doc])
const submitTicket = ()=>{
    setSending(true)
    let submit = true;
    let message = ""
    if(doc.email === '') message = "Please enter you email.\n";
    if(doc.name === '') message = message + "Please enter your name.\n";
    if(doc.message === '') message = message + "Please enter your message.\n";
    if (message !== '') submit = false;
    if(submit){
        axios.post(Types.baseUrl+'help',doc).then(res=>{
        
            if(res.data.status === 'Success'){
              alert("Help Ticket Sent!\n")
              setSending(false)
            }
          }).catch(e=>{
            alert("Error...\n"+e.message) 
            setSending(false)
          })
    }else{
        alert(message)
        setSending(false)
    }


}
const styletext = {margin:'auto',marginTop:'12px',marginLeft:'10%',fontSize:'14px',color:theme?.colors?.lighttext}
  return (
    <div style={{width:'100%',borderRadius:'8px', background:'white',height:'auto',marginTop:'10px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
       
       <Header style={theme.modalHeader}  data="Dyvy Up Support"/>
          <Text style={{...styletext,marginTop:'24px'}} data="Enter your email." 
          />
          {getTextInput("email")}
          <Text style={{...styletext}} data="Enter your name." />
          {getTextInput("name")} 
          <Text style={{...styletext}} data="What can we help with?" />
          {getTextInput("message")}  
          <Button style={{marginTop:'15px'}} isDisabled={sending} click={(e)=>{submitTicket()}} 
          
          
          name="Submit Ticket"/>
    
    </div>
  )
}
 
const dispatchToProps = dispatch =>{
    return { 
            setShowMenu:(item)=>dispatch(setShowMenu(item)),
            setShowModal:(item)=>dispatch(setShowModal(item)),
            setAuth:(item)=>dispatch(setAuth(item)),
            setModalStack:(item)=>dispatch(setModalStack(item))
          }
} 

const mapStateTopProps = state =>{
    return {...state,...state.sys,...state.firebase}
} 
export default connect(mapStateTopProps,dispatchToProps)(Support)