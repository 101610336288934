export const IS_PROD = window.env().ENV === "production"
let baseUrl = {}
const devBaseUrl = window.location.origin.includes("localhost") ? "http://127.0.0.1:5001/survsplitprod/us-central1/" :' https://us-central1-survsplitprod.cloudfunctions.net/'
const stgBaseUrl =  window.location.origin.includes("localhost") ? "http://127.0.0.1:5001/dyvyup-stg/us-central1/":"https://us-central1-dyvyup-stg.cloudfunctions.net/" 
const prodBaseUrl =  window.location.origin.includes("localhost") ?"http://127.0.0.1:5001/dyvyup-384620/us-central1/" : "https://us-central1-dyvyup-384620.cloudfunctions.net/"
let servicer = {}
const servicerDev = {
    apiKey: "AIzaSyB_mEV_PpgXTfGcZaEWbKUlogQ8zXGQmvA",
    authDomain: "survsplitprod.firebaseapp.com",
    projectId: "survsplitprod",
    storageBucket: "survsplitprod.appspot.com",
    messagingSenderId: "1096063990977",
    appId: "1:1096063990977:web:ab260c6fe196449bc2a840",
    measurementId: "G-BJB44GG6PN"
}
const servicerStg = {
    apiKey: "AIzaSyAFFqcY1Ipxh-flVoOKi8ORnQEdZWjAAXA",
    authDomain: "dyvyup-stg.firebaseapp.com",
    projectId: "dyvyup-stg",
    storageBucket: "dyvyup-stg.appspot.com",
    messagingSenderId: "871515090029",
    appId: "1:871515090029:web:5a919889a67f99c86b6364",
    measurementId: "G-74GS7E911E"
};
const servicerProd = {
    apiKey: "AIzaSyBI9rpxaMrfQrzIYmRC5ZTpZBUfUQE3ZeI",
    authDomain: "dyvyup-384620.firebaseapp.com",
    projectId: "dyvyup-384620",
    storageBucket: "dyvyup-384620.appspot.com",
    messagingSenderId: "990609217509",
    appId: "1:990609217509:web:7b3dee44ffd5e4679b410c",
    measurementId: "G-JMJ2DY8KP8"
}
switch (window.env().ENV){
case "development":
    servicer=servicerDev
    baseUrl = devBaseUrl
break;
case "staging":
    servicer=servicerStg
    baseUrl =stgBaseUrl
break;
case "production":
    servicer=servicerProd
    baseUrl = prodBaseUrl
break;
default:
break;
}
const Types = {   
    TOTAL_FEE:0.039,
    STRIPE_FEE:0.029,
    SERVICE_FEE:0.010,
        baseFirebaseServicer: servicer, 
        baseUrl:baseUrl,
 }
 export default Types;