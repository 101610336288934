import React from 'react'
import Theme from '../theme/Theme' 
export const Header = (props) => {  
  const theme = Theme();
  return (
    <div  onClick={(e)=>{
      e.preventDefault(); 
      if(props?.onClick)props?.onClick()
    }} style={{...theme?.header,...props?.style}}>{props.data}{props.children}</div>
  )
}
export default Header