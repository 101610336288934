import React from 'react'
import {setShowMenu} from '../actions'; 
import Theme from '../components/theme/Theme';
import { connect } from 'react-redux'; 
import Header from '../components/header/Header';
import Title from '../components/title/Title';
import NameLogo from '../components/nav/NameLogo';
import Button from '../components/button/Button'; 
import { setShowModal,setModalContent } from '../actions'; 
import Support from '../modals/Support';
import BottomBar from '../components/nav/BottomBar';
import DropDownBox from '../components/DropDownBox/DropDownBox';
import HowItWorks from '../components/HowItWorks/HowItWorks';
const Pricing = (props) => {
  const theme = Theme();
  return (
    <div style={{width:'100%',minHeight:'93vh'  ,height:'auto' ,display:'flex',flexDirection:'column'}}>
         
        
        <div style={{width:theme?.isPastBreak ? '80%' :'80%',padding:'15px' ,display:'flex',flexWrap:'wrap',minHeight:theme?.isPastBreak?'auto':'auto',margin:'auto',flexDirection:'column',marginTop:'5vw',marginBottom:'2vw',borderRadius:'12px'}}>
            <Header data="Pricing"  style={{margin:'auto',width:'auto'   ,padding:'8px',fontWeight:'900',marginBottom:'0vw',marginTop:'2vw',fontSize:theme?.isPastBreak?'34px':'60px',textAlign:'center' }} />
            <Title style={{margin:'auto',textAlign:'center',maxWidth:'400px',color:theme?.colors?.button,padding:'8px' ,fontWeight:'200'}} data="">So What does it cost? Lets talk pricing.<br/>
            </Title>
        </div>
           
        <div style={{width:theme?.isPastBreak ? '90%' :'60%' ,paddingBottom:'2.5%',paddingTop:'1.5%',boxShadow:'0px 0px 4px rgba(0,0,0,0.2)',backgroundColor:'whitesmoke',display:'flex',flexWrap:'wrap',minHeight:theme?.isPastBreak?'auto':'auto',margin:'auto',flexDirection:'column',marginTop:'1vw',marginBottom:'6vw',borderRadius:'12px'}}>
          <DropDownBox  minHeight={500} maxHeight='auto' name={'For service users.'}>
              <HowItWorks  text="3.9% Plus 50 cents per transaction."/> 
          </DropDownBox> 
          <DropDownBox  minHeight={500} maxHeight='auto' name={'For service providers.'}>
              <HowItWorks  text={'.1% per contract'}/> 
          </DropDownBox>  
        </div>
       
        <BottomBar/>
    </div>
  )
}
 
const dispatchToProps = dispatch =>{
    return { 
            setShowMenu:(item)=>dispatch(setShowMenu(item)),
            setModalContent:(item)=>dispatch(setModalContent(item)),
            setShowModal:(item)=>dispatch(setShowModal(item))
          }
} 

const mapStateTopProps = state =>{
    return {...state,...state.sys}
} 
export default connect(mapStateTopProps,dispatchToProps)(Pricing)