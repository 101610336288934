import React  from 'react' 
import Input from '@mui/material/Input'; 
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl'; 
import  Mail from '@mui/icons-material/Mail'; 
import  Passowrd from '@mui/icons-material/Password'; 
import Search from '@mui/icons-material/Search';

import AccountCircle from '@mui/icons-material/AccountCircle';
export const InputComponent = (props) => { 
  return (
            <FormControl  label=''   multiline={props?.multiline} rows={props?.rows}  style={{...props?.style,backgroundColor:'transparent'}}   variant="standard"> 
              <Input   multiline={props?.multiline} value={props?.value} rows={props?.rows}  {...props}  label=''  style={{...props?.style,margin:'auto'}} onClick={props?.onClick}  type={props?.label === "Password"?'password':'text'} onChange={(e)=>{props?.onChange(e)}}
                id="input-with-icon-adornment" 
                startAdornment={<InputAdornment  position="start">
                                  {props?.label === "Email"?<Mail /> :null}
                                  {props?.label === "Password"?<Passowrd /> :null}
                                  {props?.label === "Search"?<Search /> :null}
                                  {props?.label === "Username"?<AccountCircle /> :null}
                                </InputAdornment>} />
            </FormControl> 
          )
}
export default InputComponent
