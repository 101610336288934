import React  from 'react' 
import { Link } from './Link' 
import {routes,otherroutes, userroutes} from './routes'   
import {setShowMenu,setUserNav,setShowModal,setModalContent,setModalStack,setContracts,setAuth,setDoc} from '../../actions'; 
import { connect } from 'react-redux';  
import { useNavigate } from 'react-router-dom';
import Login from '../../modals/Login';
import SignUp from '../../modals/SignUp';
import Theme from '../theme/Theme';
import {AiOutlinePlusCircle,AiOutlineClose} from 'react-icons/ai'
import {RiHome4Line,RiLogoutCircleLine,RiLoginCircleLine, RiAccountPinCircleLine} from 'react-icons/ri'
import {BiPurchaseTagAlt, BiHelpCircle} from 'react-icons/bi'
import {MdOutlineRememberMe,MdAttachMoney} from 'react-icons/md'  
import { logout } from '../../utilities';
const MenuNav = (props) => { 
    const nav = useNavigate()     
    const loginFunc = ()=>{props?.setModalStack();props?.setModalContent(<Login/>);props?.setShowModal(true);props?.close() }
    const signupFunc = ()=>{props?.setModalStack();props?.setModalContent(<SignUp/>);props?.setShowModal(true);props?.close() }
    const logoutFunc = ()=>{logout(props,nav);props?.close()}   
    const styleLinks = {margin:'auto',width:'250px',marginLeft:'2.5%',minWidth:'auto',fontSize:'22px',marginTop:'1%' }
    const theme = Theme()
    const getCustStyles = (name)=>{ 
      return {backgroundColor:props?.userNav ===name ?  theme.colors.button :theme.colors.secondary,color:props?.userNav ===name ?  theme.colors.secondary :theme.colors.button,borderRadius:'4px'}
    }
  return (
    // (254, 200, 216)
    <div style={{width:'100%' ,backgroundColor:theme.colors.secondary,display:'block',overflowX:'hidden' ,flexFlow:'column',marginTop:'0px' ,height:'auto',paddingBottom:'0px',paddingLeft:'0px',flexWrap:'wrap' ,overflowY:'auto',borderBottom:'solid thin rgba(0,0,0,0.0)',marginBottom:'32px'}} >
                   
       {/* <NameLogo style={{...theme.modalHeader,margin:'auto',maxWidth:'230px',marginLeft:'2.5%',width:'auto',marginBottom:'15px',marginTop:'15px' }} data="Dyvy Up"/> */}
       
                    {window.location.pathname !== "/UserHome" ?
                    <div style={{marginTop:'0px',marginBottom:'auto',height:'auto'}}>
                        <Link icon={<RiHome4Line style={{marginRight:'3px'}}/>} subtleclick={(e)=>{ props?.setShowModal(false);props?.setModalStack();props?.close();}}  style={{...styleLinks,...getCustStyles(routes.home)}}   link={routes.home} />
                        <Link icon={<MdAttachMoney style={{marginRight:'3px'}}/>} subtleclick={(e)=>{ props?.setShowModal(false);props?.setModalStack();props?.close();}}  style={{...styleLinks,...getCustStyles(routes.pricing)}}   link={routes.pricing} /> 
                        {props?.auth?.auth ? <Link icon={<RiLogoutCircleLine style={{marginRight:'3px'}}/>}  click={(e)=>{logoutFunc()}}  style={{...styleLinks}}   link={'Logout'} /> :<Link icon={<RiLoginCircleLine style={{marginRight:'3px'}}/>}  click={(e)=>{loginFunc()}}  style={{...styleLinks}}   link={otherroutes.login} />}
                        {props?.auth?.auth ? <Link icon={<MdOutlineRememberMe style={{marginRight:'3px'}}/>}  click={(e)=>{nav("/UserHome");props?.setShowModal(false);props?.setModalStack();props?.close();}}  style={{...styleLinks}}   link={"My Account"} /> :<Link icon={<MdOutlineRememberMe style={{marginRight:'3px'}}/>}  click={(e)=>{signupFunc()}}  style={{...styleLinks}}   link={otherroutes.signUp} />}
                        <Link icon={<BiHelpCircle style={{marginRight:'3px'}}/>} subtleclick={(e)=>{ props?.setShowModal(false);props?.setModalStack();props?.close();}}  style={{...styleLinks,...getCustStyles(routes.help)}}   link={routes.help} />
                    </div>
                    : 
                    <div style={{marginTop:'0px',marginBottom:'auto',height:'auto'}}>
                        <Link icon={<RiHome4Line style={{marginRight:'3px'}}/>} click={()=>{props?.setUserNav(userroutes.myDyvy);props?.setShowModal(false);props?.setModalStack();props?.close();}} style={{...styleLinks,...getCustStyles(userroutes.myDyvy)}} link={userroutes.myDyvy} />
                        {/* <Link icon={<RiMoneyDollarCircleLine style={{marginRight:'3px'}}/>} click={()=>{props?.setUserNav(userroutes.upcoming);props?.setShowModal(false);props?.setModalStack();props?.close();}} style={{...styleLinks,borderBottom:props?.userNav ===userroutes.upcoming ? '1px solid '+theme.colors.main :'none'}} link={userroutes.upcoming} /> */}
                        {!props?.auth?.doc? null : props?.auth?.doc?.type === "user"?<Link icon={<BiPurchaseTagAlt style={{marginRight:'3px'}}/>} click={()=>{props?.setUserNav(userroutes.purchases);props?.setShowModal(false);props?.setModalStack();props?.close();}} style={{...styleLinks,...getCustStyles(userroutes.purchases)}}  link={userroutes.purchases} /> :<Link icon={<BiPurchaseTagAlt style={{marginRight:'3px'}}/>} click={()=>{props?.setUserNav(userroutes.contracts);props?.setShowModal(false);props?.setModalStack();props?.close();}} style={{...styleLinks,...getCustStyles(userroutes.contracts)}}  link={userroutes.contracts} /> }                       
                        {!props?.auth?.doc? null :props?.auth?.doc?.type === "user"?null: <Link icon={<AiOutlinePlusCircle style={{marginRight:'3px'}}/>} click={()=>{props?.setUserNav(userroutes.createContract);props?.setShowModal(false);props?.setModalStack();props?.close();}} style={{...styleLinks,...getCustStyles(userroutes.createContract)}} link={userroutes.createContract} />}
                        {!props?.auth?.doc? null :props?.auth?.doc?.type === "user"?<Link icon={<RiAccountPinCircleLine style={{marginRight:'3px'}}/>} click={()=>{props?.setUserNav(userroutes.acct);props?.setShowModal(false);props?.setModalStack();props?.close();}} style={{...styleLinks,...getCustStyles(userroutes.acct)}} link={userroutes.acct} /> : <Link icon={<RiAccountPinCircleLine style={{marginRight:'3px'}}/>} click={()=>{props?.setUserNav(userroutes.biz);props?.setShowModal(false);props?.setModalStack();props?.close();}} style={{...styleLinks,...getCustStyles(userroutes.biz)}} link={userroutes.biz} />}
                        <Link icon={<RiLogoutCircleLine style={{marginRight:'3px'}}/>}  click={(e)=>{logoutFunc()}}  style={{...styleLinks}}   link={'Logout'} /> 
                        <Link icon={<BiHelpCircle style={{marginRight:'3px'}}/>}style={{...styleLinks}}  subtleclick={(e)=>{props?.setShowModal(false);props?.setModalStack();props?.close();}}    link={userroutes.help} /> 
                    </div>}
                   
                        
    </div>
  )
}


const dispatchToProps = dispatch =>{
    return { 
            setShowMenu:(item)=>dispatch(setShowMenu(item)),
            setShowModal:(item)=>dispatch(setShowModal(item)),
            setModalContent:(item)=>dispatch(setModalContent(item)),
            setModalStack:(item)=>dispatch(setModalStack(item)),
            setAuth:(item)=>dispatch(setAuth(item)),
            setDoc:(item)=>dispatch(setDoc(item)),
            setUserNav:(item)=>dispatch(setUserNav(item)),
            setContracts:(item)=>dispatch(setContracts(item))
          }
  } 
  
  const mapStateTopProps = state =>{
    return {...state,...state.sys}
  } 
  export default connect(mapStateTopProps,dispatchToProps)(MenuNav)