import React ,{useEffect,useState} from 'react'
import {setShowMenu} from '../../../actions'; 
import { connect } from 'react-redux';   
import Text from '../../../components/text/Text'
import {setShowModal,setModalStack} from "../../../actions/index"
import Button from '../../../components/button/Button';
import Theme from '../../../components/theme/Theme';
import { setAuth } from '../../../actions';
import InputComponent from '../../../components/input/InputComponent';
import Types from '../../../utilities/Types';
import axios from 'axios'
import Header from '../../../components/header/Header';
import {BsCreditCard2Back,BsBank2} from 'react-icons/bs' 
const PaymentAdder = (props) => {
const theme = Theme() 
const baseObj = {card:'',sec:'',exyear:'',exmonth:'',zip:''}
const [doc,setDoc] = useState({...baseObj})
const [sending,setSending] = useState(false)
const [selection,setSelection] = useState('')
const getTextInput = (type,max) =>{
  const style ={width:"95%", 
  maxWidth:'95%',
  margin:'auto',  
  marginTop:'5px', 
  backgroundColor:'rgba(255,255,255,0.7)'}
  
    return  <InputComponent    inputProps={{ maxLength: max,inputMode: 'numeric' }}   style={style} type="number"   
    onChange={(e)=>{ 
      e.target.value = e.target.value.replace(/[^0-9]/g, '');
      
      if(type ==='card'){
        setDoc({...doc,[type]:e.target.value.replace(/[^0-9]/g, ''),type:getCardType(e.target.value.replace(/[^0-9]/g, ''))});
      } else {
        setDoc({...doc,[type]:e.target.value.replace(/[^0-9]/g, '')});
      }
    }} /> 
  
        
} 

useEffect(()=>{

},[doc])


const checkLuhn = (cardNo)=> {
    let nDigits = cardNo.length;

    let nSum = 0;
    let isSecond = false;
    for (let i = nDigits - 1; i >= 0; i--)
    {

        let d = cardNo[i].charCodeAt() - '0'.charCodeAt();

        if (isSecond === true)
            d = d * 2;

        // We add two digits to handle
        // cases that make two digits
        // after doubling
        nSum += parseInt(d / 10, 10);
        nSum += d % 10;

        isSecond = !isSecond;
    }
    return (nSum % 10 === 0);
}
const getCardType = (card) => {
  let type = ''
  const cardarray = card.split('')
  if(cardarray[0] === '3' && (cardarray[1] === '4' || cardarray[1] === '7')) type = 'amex';
  if(cardarray[0] === '6' ) type = 'discover';
  if(cardarray[0] === '5'  && (cardarray[1] === '1' || cardarray[1] === '2'|| cardarray[1] === '3'|| cardarray[1] === '4'|| cardarray[1] === '5') ) type = 'mastercard';
  if(cardarray[0] === '4') type = 'visa';
  
  return type 

}
const addcard = async ()=> {
    setSending(true)
    let submit = true;
    let message = ""

    if(!checkLuhn(doc.card)) message = "Please enter a valid Card Number.\n";
    if(doc.sec === '') message = message + "Please enter your CVC Code.\n";
    if(doc.exmonth === '' || doc.exmonth.length !== 2) message = message + "Please enter your 2 Digit Experation Month.\n";
    if(doc.exyear === '' || doc.exyear.length !== 4) message = message + "Please enter your 4 Digit Experation Year.\n";
    if(doc.zip === '' || doc.zip.length !== 5) message = message + "Please enter your 5 Digit Zip Code.\n";
    if (message !== '') submit = false;
    if(submit){

      const tok = await props?.auth.auth.getIdToken();

        axios.post(Types.baseUrl+'addCard',{doc:doc,token:tok}).then(res=>{
         
            if(res.data.status === 'Success'){
              props?.resetCards()
              alert(res.data.message)
              setSending(false)
              props?.setShowModal(false)
              props?.setModalStack(null)
            }
          }).catch(e=>{
            alert(e.message)
            setSending(false)
          })
    }else{
        alert(message)
        setSending(false)
    }


}

const styletext = {margin:'auto',marginTop:'12px',marginLeft:'5%' ,color:theme?.colors?.lighttext}
  return (
    <div style={{width:'100%',paddingBottom:'0px',height:'auto',display:'flex',flexDirection:'column',marginTop:'10px',justifyContent:'center',alignItems:'center'}}>
          <Header  style={theme.modalHeader} data="Add Payment Method" /> 
          <div style={{display:'flex',flexFlow:'column',height:'100%',justifyContent:'center',flexWrap:'wrap'}}>
          {selection === ''? <Button  isDisabled={sending} click={(e)=>{setSelection('card')}}  style={{width:"225px",padding:'20px',maxWidth:'80%',margin:'5px' ,marginTop:'5vh',marginBottom:'8vh',backgroundColor:theme.colors.button}}  ><BsCreditCard2Back style={{marginRight:'5px',marginLeft:'0px',fontWeight:'900'}}/> Add Credit/Debit Card</Button> :null}
          {/* {selection === ''? <Button isDisabled={sending} click={(e)=>{alert('Coming soon.');}}  style={{width:"225px",padding:'30px',maxWidth:'80%',margin:'5px' ,marginTop:'1vh',marginBottom:'5vh',backgroundColor:theme.colors.button}}  ><BsBank2 style={{marginRight:'5px',marginLeft:'0px',fontWeight:'900'}}/> Add Checking Account</Button> :null} */}
          </div>
          {selection === 'card'? 
              <>
                <Text style={{...styletext}} data="Card Number"  />
                {getTextInput("card",16)}

                 <Text style={{...styletext}} data="Card CVC"  />
                 {getTextInput("sec",8)}
                <div style={{width:'90%',margin:'auto',display:'flex',marginBottom:'10px'}}>
                    <div style={{margin:'auto'}}>
                    <Text style={{...styletext}} data="Exp Year"  />
                    {getTextInput("exyear",4)}
                    </div>
                    <div style={{margin:'auto'}}>
                    <Text style={{...styletext}} data="Exp Month"  />
                    {getTextInput("exmonth",2)}
                    </div>
                    <div style={{margin:'auto'}}>
                      <Text style={{...styletext}} data="Zip"  />
                      {getTextInput("zip",5)}
                    </div>
                </div>
                <Button isDisabled={sending}  click={addcard}  style={{marginTop:'10px'}} name="Add" />
              </>
          :null}
          {selection === 'account'?
                <>                 
                  <Button  style={{color:theme?.colors?.main,border:'1px solid '+theme?.colors?.main,marginTop:'10px',backgroundColor:theme?.colors?.secondary}} name="Add" />
                </>
          :null}
           
    
    </div>
  )
}
 
const dispatchToProps = dispatch =>{
    return { 
            setShowMenu:(item)=>dispatch(setShowMenu(item)),
            setShowModal:(item)=>dispatch(setShowModal(item)),
            setAuth:(item)=>dispatch(setAuth(item)),
            setModalStack:(item)=>dispatch(setModalStack(item))
          }
} 

const mapStateTopProps = state =>{
    return {...state,...state.sys,...state.firebase}
} 
export default connect(mapStateTopProps,dispatchToProps)(PaymentAdder)