import React ,{ useState} from 'react'
import {setShowMenu} from '../actions'; 
import { connect } from 'react-redux';  
import { useNavigate } from 'react-router-dom'; 
import Text from '../components/text/Text'
import {setShowModal,setModalStack} from "../actions/index"
import Button from '../components/button/Button'; 
import { setAuth } from '../actions';
import InputComponent from '../components/input/InputComponent';
import NameLogo from '../components/nav/NameLogo'; 
import Theme from '../components/theme/Theme'; 
const Login = (props) => { 
const [showPass,setShowPass] = useState(false)
const [user,setUser] = useState({name:'',pass:''})
const getTextInput = (isPass) =>{
 
  const style ={width:"95%", 
  maxWidth:'95%',
  margin:'auto',
  marginLeft:'2.5% ',  
  marginTop:'1px', 
  backgroundColor:'rgba(255,255,255,0.7)'}
  if(isPass){
        return <InputComponent style={style} label="Password" onChange={(e)=>{setUser({...user,pass:e.target.value})}} />  
  } else {
        return <InputComponent style={style} label="Email" onChange={(e)=>{setUser({...user,name:e.target.value})}} /> 
  }
}
  const theme = Theme()
  const nav = useNavigate()
  return (
    <div style={{width:'100%',minHeight:'auto', borderRadius:'8px',background:'white',height:'auto',display:'flex',flexDirection:'column' }}>
       
       <NameLogo style={{...theme.modalHeader,margin:'auto',marginLeft:'auto',width:'auto',marginBottom:'25px',marginTop:'15px' }} data="Dyvy Up"/>
          <Text style={{margin:'auto',marginLeft:'10%',marginTop:'10px'}} data="Enter your email." 
          />
          {getTextInput()}
          <Text style={{margin:'auto',marginLeft:'10%',marginTop:'10px'}} data="Enter your password." />
          {getTextInput(true)}
          <Button style={{marginTop:'25px'}} isDisabled={showPass}
           click={()=> 
            {
            setShowPass(true);
            props?.firebase?.signInWithEmailAndPassword( props?.firebase?.auth,user.name,user.pass)
              .then(user=>{
                  if(user.user.uid){
                
                  setShowPass(false);nav("/UserHome");
                  props?.setShowModal(false);
                  props?.setModalStack([])
                  }
                  else{
                    alert("no user" +JSON.stringify(user));
                    setShowPass(false)
                  }}).catch(e=>{alert(e.message);setShowPass(false)});
             
            }}  
             name="Login"/>
          
          <Text  style={{width:"auto",maxWidth:'auto',margin:'auto' ,borderBottom:'1px solid black',marginTop:'20px',marginBottom:'30px',cursor:'pointer'}} data="Forgot Password"/>
       
    </div>
  )
}
 
const dispatchToProps = dispatch =>{
    return { 
            setShowMenu:(item)=>dispatch(setShowMenu(item)),
            setShowModal:(item)=>dispatch(setShowModal(item)),
            setAuth:(item)=>dispatch(setAuth(item)),
            setModalStack:(item)=>dispatch(setModalStack(item))
          }
} 

const mapStateTopProps = state =>{
    return {...state,...state.sys,...state.firebase}
} 
export default connect(mapStateTopProps,dispatchToProps)(Login)