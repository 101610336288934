export const routes = {
    home:"Home",
    pricing:"Pricing",
    help:"Help",
    // faq:"FAQ",
    // contact:"Contact",
}
export const otherroutes = {
    // hiw:"How it Works",
    // fs:"For Servicers",
    login:'Login',
    signUp:'Signup'
}

export const userroutes = {
    myDyvy:'My Dyvy Up',
    upcoming:"Upcoming Payments",
    purchases:'Purchases',
    help:'Help',
    acct:'Account Settings',
    biz:'Business Settings',
    search:'Search',
    contracts:'Contracts',
    createContract:'Create a Contract'
}

export const settingroutes = {
    Account:'Account',
    Payments:'Payments',
    Financials:'Financials'
}