import {   } from "../actions";
import GetFirebase from "../utilities/GetFirebase";
const firebase = GetFirebase()
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = {firebase:firebase}, action)=>{
    switch(action.type){
        // case FETCH_USERS:
        //     return action.payload.data;
        default:
            return state;
    }
}