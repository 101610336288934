import React,{useEffect, useState} from 'react' 
import { connect } from 'react-redux';   
import {   setModalStack,setShowModal,setModalContent } from '../../actions';
import Header from '../header/Header';
import { Link } from '../nav/Link';
import Theme from '../theme/Theme';

const dispatchToProps = dispatch =>{
    return {  
            setShowModal:(item)=>dispatch(setShowModal(item)),
            setModalContent:(item)=>dispatch(setModalContent(item)), 
            setModalStack:(item)=>dispatch(setModalStack(item)), 
            }
} 
const Selector = (props) => {  
    const theme = Theme()
    const [items,setItems] = useState([])
    const [selection,setSelection] = useState('')
     
    useEffect(()=>{  
        setItems(props?.items)
        if(props?.items && props?.items[0] && selection === '') {
            setSelection(props?.items[0]?.link)
        } 
    },[props?.auth?.contracts,props?.auth?.completedcontracts])
    useEffect(()=>{

    },[items,selection])
    
    return ( 
        <div  style={{width:theme?.isPastBreak?"100%":'98%',paddingTop:'0px',paddingBottom:'0px',boxShadow:'0px 0px 10px rgba(0,0,0,0.15)',height:'auto',margin:'auto',borderRadius:theme?.isPastBreak?'0px':'8px',backgroundColor:theme.colors.secondary ,display:'flex',flexDirection:'column',marginTop:theme?.isPastBreak?'0vh':'0px',marginBottom:'5vh',justifyContent:'center'}}>
            <Header style={{color:theme?.colors.button,marginLeft:'10px',fontSize:theme?.isPastBreak? "16px" :"24px",marginTop:'16px',marginBottom:'0px',marginRight:'10px'  }} >{props?.title}</Header>
            <div style={{width:'100%',height:'60px',alignItems:'center',display:'flex',flexWrap:'no-wrap',flexDirection:"row",overflowX:'auto',borderRadius:'0px'  ,backgroundColor:'white'  }}>
                {items?.map((item, index)=>{
                    return <Link icon={''} key={item.link+index} click={()=>setSelection(item.link)} style={{marginTop:'auto',display:'flex',flexShrink:'0' ,fontSize:'14px',minWidth:'auto',marginBottom:'auto',marginRight:'5px',padding:'8px',textAlign:'center' ,marginLeft:'10px' ,width:'`auto`',fontWeight:'900',boxShadow:selection === item.link ? '0px 0px 10px'+theme.colors.main :'none',backgroundColor:selection === item.link ? theme.colors.button :'transparent',color:selection === item.link ? theme?.colors?.secondary :'grey' ,transition:'all ease-in-out 250ms',borderRadius:'8px'}}  link={item.link} />
                })} 
            </div>
            <div  style={{width:'100%',display:'flex',marginTop:'-1px',paddingTop:'15px',flexWrap:'wrap',minHeight:'40vh',backgroundColor:'white',paddingBottom:'10px',borderBottomLeftRadius:'8px',borderBottomRightRadius:'8px' }}>
                {items?.map(item=>{
                return selection === item.link  ? <div style={{transition:'all 500ms ease-in-out',width:'100%',margin:'auto' ,paddingTop:'0px',paddingBottom:'5px',borderRadius:'0px' ,marginTop:'0px',height:'auto',marginBottom:'0px' ,backgroundColor:'white',display:'flex',boxShadow:'none',flexDirection:'column'}}>
                                                        {item.screen}
                                                  </div> 
                                                : null
                })} 
            </div>
        </div>
    )
}

const mapStateTopProps = state =>{
    return {...state,...state.sys}
} 
export default connect(mapStateTopProps,dispatchToProps)(Selector)
