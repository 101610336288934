import React, {useState, useEffect} from 'react' 
import {GiPayMoney} from 'react-icons/gi'
import Text from '../text/Text'
import Theme from '../theme/Theme'
import {setShowMenu,setShowModal,setModalContent,setContracts,setCompletedContracts} from '../../actions'; 
import { connect } from 'react-redux';  
import { getPaymentCount, milliToFormattedDate } from '../../utilities'
import axios from 'axios'
import Types from '../../utilities/Types'
import Contract from '../../screens/userhome/modals/Contract';

const ContractCard = (props) => {

    const [sending,setSending] = useState(false) 
 
    const theme = Theme()
 
    const click = async (e)=>{
      e.stopPropagation();
      if(!window.confirm("Are you sure you wish to charge your card now?"))  return 
      setSending(true) 
      const tok = await props?.auth.auth.getIdToken();
      await axios.post((Types.baseUrl+"chargeCont"),{contractId:props?.item?.id, token:tok}).then(async item=>{  
        window.alert(item?.data.message)

        props.resetUserData(); 
        setSending(false)
        props?.setShowModal(false)
        props?.setModalContent(null)
      }).catch(e=>{
        window.alert(JSON.stringify(e.message))

        props.resetUserData();
        props?.setShowModal(false)
        props?.setModalContent(null)
        setSending(false)
      })
    }

    useEffect(() => {
      
    }, [props?.item])
    
    const getUIRegular = () =>{
      return <><Text style={{marginTop:'auto',marginBottom:'auto',fontSize:'15px',width:'160px',maxWidth:'160px',overflowX:'auto' ,marginLeft:'0px' }}>  {props?.auth?.doc?.type === "provider"?props?.item?.CustomerName: props?.item?.bizName}</Text>
      {theme?.isPastBreak? null:<Text style={{marginTop:'auto',fontSize:'15px',width:'125px',maxWidth:'125px',marginLeft:'5%',marginBottom:'auto'}}>  {getPaymentCount(props?.item?.receipts)===4? "Complete":props?.pending?"Pending Confirmation":"In Progress"}</Text>}
      {props?.pending?null:<Text style={{marginTop:'auto',fontSize:'15px',width:'125px',maxWidth:'125px',marginLeft:'5%',marginBottom:'auto'}}>  {props?.isUpcoming? props?.item?.receipts.length === 0 ? milliToFormattedDate(Date.now()) : milliToFormattedDate(parseFloat(props?.item?.receipts[getPaymentCount(props?.item?.receipts)]?.due)) : milliToFormattedDate(parseFloat(props?.item?.confirmed))}</Text>}
      <Text style={{marginTop:'auto',width:'125px',fontSize:'15px',maxWidth:'125px',marginLeft:'5%',marginBottom:'auto'}}>  ${props?.isUpcoming? (props?.item?.total/400).toFixed(2):(props?.item?.paid/100).toFixed(2)}</Text>
      <Text style={{marginTop:'auto',width:'125px',fontSize:'15px',maxWidth:'125px',marginBottom:'auto',marginLeft:'5%',marginRight:'auto'}}>${(props?.item?.total/100).toFixed(2)}</Text>
      {getPaymentCount(props?.item?.receipts)===4 || props?.auth?.doc?.type === "provider"?null :
      
      <div style={{margin:'auto',marginRight:'4px'}}><GiPayMoney isDisabled={sending}  onClick={click}  
      style={{ height:'24px',width:'24px',marginTop:'2px',color:theme?.colors?.button   }} name={props?.isUpcoming?"Pay Now":"Pay Early"}/>
      </div>}
      </>
    }

    const getMobile = () =>{
      return<div style={{display:'flex',flexDirection:'column',width:'100%'}}>
      <div style={{display:'flex',width:'100%'}}>
        <Text style={{marginTop:'auto' ,width:'auto',maxWidth:'auto',fontSize:'14px',marginRight:'auto' ,marginLeft:'5px'}}>   {props?.auth?.doc?.type === "provider"?props?.item?.CustomerName: props?.item?.bizName} </Text> 
        {props?.pending?null:<Text style={{marginTop:'auto',width:'auto',maxWidth:'auto',fontSize:'14px',marginLeft:'auto',marginRight:'5px'}}>  {props?.isUpcoming? props?.item?.receipts.length === 0 ? milliToFormattedDate(Date.now()) : milliToFormattedDate(parseFloat(props?.item?.receipts[getPaymentCount(props?.item?.receipts)]?.due)) : milliToFormattedDate(parseFloat(props?.item?.confirmed))}</Text> }
      </div>
      {props?.isUpcoming?
      <div style={{display:'flex',width:'100%'}}>
      <Text style={{marginTop:'auto' ,width:'auto',fontSize:'12px',maxWidth:'auto',marginRight:'auto' ,marginLeft:'5px'}}>  Due ${(props?.item?.total/400).toFixed(2)}</Text>  
    </div>
      :<div style={{display:'flex',width:'100%'}}>
        <Text style={{marginTop:'auto' ,width:'auto',opacity:'0.95',fontSize:'12px',maxWidth:'auto',marginRight:'auto' ,marginLeft:'5px'}}>  ${props?.isUpcoming? (props?.item?.total/400).toFixed(2):(props?.item?.paid/100).toFixed(2)} / ${(props?.item?.total/100).toFixed(2)}</Text> 
        <Text style={{marginTop:'auto',width:'auto',fontSize:'12px',maxWidth:'auto',marginLeft:'auto',marginRight:'5px'}}> Paid {getPaymentCount(props?.item?.receipts)}/4</Text> 
      </div> }
      {/* {getPaymentCount(props?.item?.receipts)===4|| props?.auth?.doc?.type === "provider"?null :<Button isDisabled={sending}  click={click} hover={{borderBottom:'0px solid white',color:theme?.colors?.main,boxShadow:'hidden'}} 
      style={{ padding:'0px',height:'auto',borderRadius:'2px',minHeight:'auto',paddingLeft:'0px',paddingRight:'0px',margin:'auto',marginTop:'auto',marginRight:'5px',minWidth:'auto',fontSize:'14px',width:'auto',borderBottom:'3px solid '+theme?.colors?.main  ,color:theme?.colors?.main }} name={props?.isUpcoming?"Pay Now":"Pay Early"}/>} */}
      </div>
    }
  return (
    <div key={props?.item?.id} onClick={(e)=>{e.preventDefault();e.stopPropagation();props?.setModalContent(<Contract loading={sending} payNow={click} data={props?.item}/>);props?.setShowModal(true);}}  style={{display:'flex',margin:'auto',width:'90%',marginLeft:'auto',marginRight:'auto',marginTop:'2px',marginBottom:'2px',padding:'5px',paddingTop:'2px', backgroundColor:props?.index%2 === 0? 'rgba(0,0,0,0.01)' :'rgba(0,0,0,0.03)',borderRadius:'8px',overflow:'hidden'}}>
        {theme?.isPastBreak?getMobile() :getUIRegular()}
    </div>
  )
}

const dispatchToProps = dispatch =>{
    return { 
            setShowMenu:(item)=>dispatch(setShowMenu(item)),
            setShowModal:(item)=>dispatch(setShowModal(item)),
            setModalContent:(item)=>dispatch(setModalContent(item)),
            setContracts:(item)=>dispatch(setContracts(item)),
            setCompletedContracts:(item)=>dispatch(setCompletedContracts(item))
          }
} 

const mapStateTopProps = state =>{
    return {...state,...state.sys}
} 
export default connect(mapStateTopProps,dispatchToProps)(ContractCard)  