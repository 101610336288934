import React ,{useEffect,useState} from 'react'
import {setShowMenu} from '../../../actions'; 
import { connect } from 'react-redux';  
import Text from '../../../components/text/Text'
import {setShowModal,setModalStack} from "../../../actions/index"
import Button from '../../../components/button/Button';
import Theme from '../../../components/theme/Theme';
import { setAuth } from '../../../actions';
import InputComponent from '../../../components/input/InputComponent';
import Types from '../../../utilities/Types';
import axios from 'axios'  
import { Circles } from 'react-loader-spinner';


const ContractCreator = (props) => {
const theme = Theme()  
const [doc,setDoc] = useState({Total:'','User Email':'','Short Description':''})
const [sending,setSending] = useState(false) 

const containsMoreThanTwo = (checker) => {
  let check = 0
  checker.split('').forEach((char)=>{
          if(char === '.') check += 1
        })
        if(check > 1){
          return true
        }else{
          return false
        }
}
const getTextInput = (type,max,isnumber) =>{
  const style ={width:"97.5%",  
  margin:'auto',  
  marginLeft:'1.25%',
  marginTop:'5px', 
  backgroundColor:'rgba(255,255,255,0.7)'}
  
    return  <InputComponent    inputProps={isnumber?{ maxLength: max,inputMode: 'decimal' }:{}}   style={style} type={isnumber?"decimal":"text"   }
    onChange={(e)=>{ 
      let value = e.target.value
     
      let onlyNumbers = value.replace(/[^\d.]/g, '');
      if(containsMoreThanTwo(onlyNumbers)){
        onlyNumbers = onlyNumbers.slice(0,-1)
      }

      if(isnumber) 
      {e.target.value = onlyNumbers;
      value = onlyNumbers;}  
      setDoc({...doc,[type]:value})

    }} /> 
  
        
} 

useEffect(()=>{
 
},[doc])


  
const createContract = async ()=> {
    setSending(true)
    let submit = true;
    let message = ""
 
    if(!doc["User Email"]) message = message + "Please enter user email.\n";
    if(!doc["Total"]) message = message + "Please enter a valid total.\n";
    if(!doc["CustomerName"]) message = message + "Please enter a customer name.\n";
    if(!doc["Short Description"]) message = message + "Please enter your short service description.\n"; 
    if (message !== '') submit = false;
    if(submit){

      const tok = await props?.auth.auth.getIdToken();

        axios.post(Types.baseUrl+'createContract',{doc:doc,token:tok}).then(res=>{ 
           
            if(res.data.status === 'Success'){  
               
              } 
            setSending(false) 
            setDoc({})
            alert(res.data.message) 
          }).catch(e=>{
            setSending(false)
            setDoc({})
            alert(e.message)
          })
    }else{
        setDoc({})
        setSending(false)
        alert(message)
    }


}

const styletext = {margin:'auto',marginTop:'12px',marginLeft:'3%',fontSize:'16px',color:theme?.colors?.lighttext}
  return (
    <>
    {/* <Title style={{ marginBottom:'5px'}} data="Create a Contract" />  */}
    
    {sending? <Circles
                  height="40"
                  width="40"
                  color={theme?.colors?.main}
                  ariaLabel="circles-loading"
                  wrapperStyle={{margin:'auto',backgroundColor:'white',borderRadius:'25px',padding:'5px',marginTop:'20px'}}
                  wrapperClass=""
                  visible={true}
                />  :<div style={{width:'100%',maxWidth:'500px',backgroundColor:'white' ,margin:'auto',marginLeft:'0px',paddingBottom:'10px',height:'auto',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
          
          <Text style={{...styletext}} ><b>Paid in full with <br/> 4 bi-weekly payments of {doc['Total']?(parseFloat(doc['Total'])/4).toFixed(2):'...'} </b><br/></Text>
          <Text style={{...styletext}} data="User Email"  />
          {sending?null:getTextInput("User Email",100)}
          <Text style={{...styletext}} data="Customer Name"  />
          {sending?null:getTextInput("CustomerName")}
          <Text style={{...styletext}} data="Total"  />
          {sending?null:getTextInput("Total",100,true)}
          <Text style={{...styletext}} data="Short Description Of Service"  />
          {sending?null:getTextInput("Short Description",100)}
            
    </div>}
    <Button isDisabled={sending}
           click={createContract} 
           style={{paddingLeft:'15px',paddingRight:'15px',border:'1px solid black',color:theme?.colors?.secondary,fontWeight:'900',marginTop:'20px',marginBottom:'10px'}}
             name="Send Now"/>  
    </>
  )
}
 
const dispatchToProps = dispatch =>{
    return { 
            setShowMenu:(item)=>dispatch(setShowMenu(item)),
            setShowModal:(item)=>dispatch(setShowModal(item)),
            setAuth:(item)=>dispatch(setAuth(item)),
            setModalStack:(item)=>dispatch(setModalStack(item))
          }
} 

const mapStateTopProps = state =>{
    return {...state,...state.sys,...state.firebase}
} 
export default connect(mapStateTopProps,dispatchToProps)(ContractCreator)