import React , {useState,useEffect} from 'react'
import {setShowMenu,setShowModal,setModalContent} from '../../../actions'; 
import { connect } from 'react-redux';  
import Text from '../../../components/text/Text'
import Theme from '../../../components/theme/Theme'  
import ContractCard from '../../../components/contractcard/ContractCard';

const History = (props) => {
    const [history,setHistory] = useState([])
    const theme = Theme() 
    useEffect(()=>{
        let localdata = [...props?.data]
        localdata = localdata.filter(item => {
          if(props?.pending){
            return item.certified === false
          }else{
            return item.certified === true
          }
          })
        localdata.sort((a,b)=> { return b.lastpaid - a.lastpaid})
        setHistory(localdata)
    },[props?.data])
    useEffect(()=>{

    },[history])
   
  return (
    <div style={{width:'100%',height:'auto',display:'flex',flexDirection:'column',justifyContent:'center'}}>
    {/* <Title style={{ marginBottom:'5px',}} data={props.title} /> */}
    <div style={{display:'flex',width:'90%',marginLeft:'0%',marginTop:'3%'}}>
    {theme?.isPastBreak? null:<>
    <Text style={{width:'150px',maxWidth:'150px',marginLeft:'5%',fontWeight:'900'}}>{props?.auth?.doc?.type === "provider"?"Customer": "Merchant"}</Text>
     <Text style={{width:'125px',marginLeft:'5%',fontWeight:'900'}}>Status</Text>
    {props?.pending?null:<Text style={{width:'125px',marginLeft:'5%',fontWeight:'900'}}>Purchased</Text>}
    <Text style={{width:'125px',marginLeft:'5%',fontWeight:'900'}}>Paid</Text>
    <Text style={{width:'125px',marginLeft:'5%' ,fontWeight:'900'}}>Total</Text>
    </>}
    </div>
        {history.map((item,index)=>{
            return <ContractCard  resetUserData={props?.resetUserData} pending={props?.pending} index={index} item={item}/>
        })}
    </div>
  )
}
 
const dispatchToProps = dispatch =>{
    return { 
            setShowMenu:(item)=>dispatch(setShowMenu(item)),
            setShowModal:(item)=>dispatch(setShowModal(item)),
            setModalContent:(item)=>dispatch(setModalContent(item)),
          }
} 

const mapStateTopProps = state =>{
    return {...state,...state.sys}
} 
export default connect(mapStateTopProps,dispatchToProps)(History) 