import React, {useEffect } from 'react'
import {setShowMenu} from '../../../actions'; 
import { connect } from 'react-redux'; 
import Header from '../../../components/header/Header';
import UpcomingPayments from '../components/UpcomingPayments';
import ContractRequests from '../components/ContractRequests';
import MainDiv from '../components/MainDiv';
const Payments = (props) => {
  useEffect(()=>{

  },[props?.auth?.contracts])
  return (
   <MainDiv>
        <Header data ={"Upcoming Payments"} />
        <UpcomingPayments  data={props?.auth?.contracts}/>
        <ContractRequests data={props?.auth?.contracts} />
    </MainDiv>
  )
}
 
const dispatchToProps = dispatch =>{
    return { 
            setShowMenu:(item)=>dispatch(setShowMenu(item))
          }
} 

const mapStateTopProps = state =>{
    return {...state,...state.sys}
} 
export default connect(mapStateTopProps,dispatchToProps)(Payments)