import React  from 'react'
import {setShowMenu,setDoc,setShowModal,setModalContent} from '../../../actions'; 
import { connect } from 'react-redux';  
import Cards from '../../../components/card/Cards';
import MainDiv from '../components/MainDiv';
import Selector from '../../../components/selector/Selector';
import UserEditor from '../components/UserEditor';


const Settings = (props) => { 
  
  return (
   <MainDiv>
     <Selector key="setting" title={props?.auth?.doc?.name+'\'s Settings'} items={[{link:'Settings',screen:<UserEditor/>} ,{link:'Payment Methods',screen: <Cards/>  }]} /> 
    </MainDiv>
    
  )
}
 
const dispatchToProps = dispatch =>{
    return { 
            setShowMenu:(item)=>dispatch(setShowMenu(item)),
            setDoc:(item)=>dispatch(setDoc(item)),
            setShowModal:(item)=>dispatch(setShowModal(item)),
            setModalContent:(item)=>dispatch(setModalContent(item))
          }
} 

const mapStateTopProps = state =>{
    return {...state,...state.sys}
} 
export default connect(mapStateTopProps,dispatchToProps)(Settings)