import React from 'react'
import Theme from '../theme/Theme' 
import { useNavigate } from "react-router-dom"; 
import Text from '../text/Text'
import {TbArrowCurveRight} from 'react-icons/tb'
const NameLogo = (props) => {
  const theme = Theme()
  const nav = useNavigate()
  return (
    <div onClick={()=>{nav("/")}} style={{display:'flex',flexWrap:'nowrap',cursor:'pointer',height:'auto',flexFlow:'row',fontFamily:theme.fonts.main,letterSpacing:'1px',color:theme.colors.button,margin:'auto' ,...theme?.logoStyle,...props?.style}}>
        <Text style={{margin:'auto' ,color:theme.colors.button,fontWeight:'900' }}  data={props?.frontText ? props?.frontText+" Dyvy Up":"Dyvy Up"}/>
         <TbArrowCurveRight style={{margin:'auto',color:theme.colors.button,fontWeight:'900'}}/>        
        </div>
  )
}

export default NameLogo