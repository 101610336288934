import React, {useEffect} from 'react'
import {setShowMenu, setUserData} from './actions'; 
import { connect } from 'react-redux'; 
import {
    Routes,
    Route, 
    useLocation
  } from "react-router-dom"; 
  import Home from "./screens/Home";
  import Modal from "./components/modal/Modal";
  import Toolbar from "./components/nav/Toolbar";
import Login from './modals/Login';
import SignUp from "./modals/SignUp" 
import Help from './screens/Help'; 
import UserHome from './screens/userhome/UserHome';  
import { getAuth,onAuthStateChanged } from "firebase/auth"; 
import { setAuth,setDoc,setUserNav,setPendingContracts,setCards,setNotifications,setContracts,setCompletedContracts } from './actions';
import axios from 'axios';
import Types from './utilities/Types';
import GetFirebase from './utilities/GetFirebase';
import Pricing from './screens/Pricing';
 
const Main = (props) => { 
  const firebase = GetFirebase()
  const loc = useLocation()
    const setUserData = async (user,listener,listcompletedcont,listnotif) => {
       let tok = await user.getIdToken();
       await axios.post(Types.baseUrl+"getUserInfo",{token:tok}).then(async data=>{   
        listnotif = await firebase.myGetListenerOrderVar("users/"+user.uid+"/notifications",props?.setNotifications,"time","asc")
        if(data?.data?.doc?.type === "user") listener = await firebase.myGetListenerUser("contracts", props?.setContracts,user.uid )
        if(data?.data?.doc?.type === "user") listcompletedcont = await firebase.myGetListenerUser("completedcontracts", props?.setCompletedContracts,user.uid ) 
        if(data?.data?.doc?.type === "provider") listener = await firebase.myGetListenerService("contracts", props?.setContracts,user.uid )
        if(data?.data?.doc?.type === "provider") listcompletedcont = await firebase.myGetListenerService("completedcontracts", props?.setCompletedContracts,user.uid ) 
         props?.setUserNav('My Dyvy Up') 
         if(data.data && data.data.cards){
          props?.setCards([...data?.data?.cards]) 
          props?.setDoc({...data?.data?.doc}) 
         }
         props?.setAuth(user) 
    }).catch(e=>{
      console.log("fetchig user error ",e)
    });
  }
    useEffect(()=>{
      let listener  = null
      let listcompletedcont = null
      let listnotif = null
      const unsubscribe = onAuthStateChanged(getAuth(),async (user) => { 
        if (user) {
            // ...your code to handle authenticated users.  
            setUserData(user,listener,listcompletedcont,listnotif)   
        } else {
            props?.setDoc(null)
            props?.setNotifications([])
            props?.setContracts([])
            props?.setCompletedContracts([])
            props?.setCards([])
            props?.setAuth(null)
        }
    });
    return () => {
      if(listener)listener()
      if(listcompletedcont)listcompletedcont()
      if(listnotif)listnotif()
      if(unsubscribe)unsubscribe()
    }
    },[])

    useEffect(()=>{ 
    },[props?.auth?.contracts])
    
    useEffect(()=>{
        window.scroll(0,0)
    },[loc])


  return (
    <div style={{height:props?.showModal?"100%":"auto",overflowY:props?.showModal?"none":"auto"}}>
      <Toolbar/>
      <Modal/>
      <Routes>  
          <Route path="/Login" element={<Login resetUserData={()=>{setUserData(props?.auth?.auth?.uid)}} />} /> 
          <Route path="/Signup" element={<SignUp />} /> 
          <Route path="/Home" element={<Home />} />  
          <Route path="/Pricing" element={<Pricing />} />  
          <Route path="/UserHome" element={<UserHome resetUserData={()=>{setUserData(props?.auth?.auth?.uid)}}   />} />  
          <Route path="/Help" element={<Help />} />  
          <Route path="/" element={<Home />} />  
      </Routes>
    </div>
  )
}
 
const dispatchToProps = dispatch =>{
    return { 
            setShowMenu:(item)=>dispatch(setShowMenu(item)),
            setAuth:(item)=>dispatch(setAuth(item)),
            setDoc:(item)=>dispatch(setDoc(item)),
            setUserNav:(item)=>dispatch(setUserNav(item)),
            setCards:(item)=>dispatch(setCards(item)),
            setContracts:(item)=>dispatch(setContracts(item)),
            setCompletedContracts:(item)=>dispatch(setCompletedContracts(item)),
            setUserData:(item)=>dispatch(setUserData(item)),
            setPendingContracts:(item)=>{dispatch(setPendingContracts(item))},
            setNotifications:(item)=>{dispatch(setNotifications(item))}
          }
} 

const mapStateTopProps = state =>{
    return {...state,...state.sys}
} 
export default connect(mapStateTopProps,dispatchToProps)(Main)